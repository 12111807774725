import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class HomeService {
  notification;
  subject = new Subject<any>();
  agentOnlineStatus: any;
  message: any;
  public token;
  private readonly bellMessage = new Subject<any>();
  private readonly chatBox = new Subject<any>();
  private readonly notificationSubject = new Subject<any>();
  notificationSubjectState = this.notificationSubject.asObservable();

  private readonly nplagentMessages = new Subject<any>();
  nplagentMessagesState = this.nplagentMessages.asObservable();

  private readonly toggleSubject = new Subject<any>();
  toggleSubjectState = this.toggleSubject.asObservable();

  public downloadLoader = new BehaviorSubject<boolean>(false);
  downloadLoaderState = this.downloadLoader.asObservable();
  replyStatus: any;

  constructor() {
    const userData = JSON.parse(localStorage.getItem('user'));
    this.token = userData?.token ?? '';
  }

  updatedDownloadLoader(data) {
    this.downloadLoader.next(data);
  }

  setNotification(val) {
    this.notification = val;
  }

  getNotification() {
    return this.notification;
  }

  sendMessage(message) {
    this.subject.next(message);
  }
  sendNotificationMessage(message) {

    this.notificationSubject.next(message);
  }

  notificationState(): Observable<any> {

    return this.notificationSubject.asObservable();
  }

  sendNlpAgentMessage(message) {

    this.nplagentMessages.next(message);
  }

  nlpAgentState(): Observable<any> {

    return this.nplagentMessages.asObservable();
  }

  toggleMessage(message) {
    this.toggleSubject.next(message);
  }
  getNotificationMessage() {
    return this.message;
  }

  sendbellMessage(message) {
    this.bellMessage.next(message);
  }
  getbellMessage(): Observable<any> {
    return this.bellMessage.asObservable();
  }

  sendChatBoxMessages(message) {
    this.chatBox.next(message);
  }
  getsendChatBoxMessages(): Observable<any> {
    return this.chatBox.asObservable();
  }

  clearMessage() {
    this.subject.next(null);
  }

  agentStatus(message) {

    this.agentOnlineStatus = message || {};
  }
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  setReplyStatus(val) {
    this.replyStatus = val;
  }

  getReplyStatus() {
    return this.replyStatus;
  }
}
