import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard.component';
import { HomeComponent } from './home/home.component';
import { ListComponent } from './home/conversation/list/list.component';
import { AuthGuardService } from './auth-guard.service';
import { AuthGuardMainService } from '../auth-guard-main.service';
import { environment } from '../../environments/environment';
import {FlowbuilderV3Component} from './home/flowbuilder-v3/flowbuilder-v3.component';
import { RagComponent } from './home/rag/rag.component';
import { QuickreplyComponent } from './home/quickreply/quickreply.component';
import { FlexBroadcastComponent } from './home/flex-broadcast/flex-broadcast.component';
import { RichMenusComponent } from './home/rich-menus/rich-menus.component';
import { RichmenuListComponent } from './home/rich-menus/richmenu-list/richmenu-list.component';

const routes: Routes = [
    {
        // https://github.com/angular/angular/issues/18662
        // Path can only use variables that are only exported (Not modified after import)
        path: environment.dashboardRoute,
        component: DashboardComponent,
        canActivate: [AuthGuardMainService],
        canActivateChild: [AuthGuardService],
        children: [
            {
                path: '',
                component: HomeComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'overviews',
                        pathMatch: 'full'
                    },
                    {
                        path: 'overviews',
                        loadChildren: () => import('./home/overview/overview.module').then(m => m.OverviewModule),
                      },
                    // { path: 'overviews', component: OverviewComponent, data: { title: 'Overview' } },
                    {
                        path: 'conversations',
                        loadChildren: () => import('./home/conversation/conversation.module').then(m => m.ConversationModule),
                      },
                      {
                        path: 'liveagents',
                        loadChildren: () => import('./home/conversation/live-agents/live-agents.module').then(m => m.LiveAgentsModule),
                      },
                      {
                        path: 'history',
                        loadChildren: () => import('./home/recent-conversation/recent-conversation.module').then(m => m.RecentConversationModule),
                      },
                      {
                        path: 'conversationalelements',
                        loadChildren: () => import('./home/question/question.module').then(m => m.QuestionModule),
                      },
                      {
                        path: 'templates',
                        loadChildren: () => import('./home/templates/templates.module').then(m => m.TemplatesModule),
                      },
                      {
                        path: 'listtemplates',
                        loadChildren: () => import('./home/templates/list-templates/list-templates.module').then(m => m.ListTemplatesModule),
                      },
                      {
                        path: 'contacts',
                        loadChildren: () => import('./home/contacts/contacts.module').then(m => m.ContactsModule),
                      },
                      {
                        path: 'trialusers',
                        loadChildren: () => import('./home/trialusers/trialusers.module').then(m => m.TrialusersModule),
                      },
                      {
                        path: 'agentgroups',
                        loadChildren: () => import('./home/agentgroups/agentgroups.module').then(m => m.AgentgroupsModule),
                      },
                      {
                        path: 'reports',
                        loadChildren: () => import('./home/analytics/analytics.module').then(m => m.AnalyticsModule),
                      },
                      {
                        path: 'agentallocation',
                        loadChildren: () => import('./home/agentallocation/agentallocation.module').then(m => m.AgentallocationModule),
                      },
                      {
                        path: 'digitalassistant',
                        loadChildren: () => import('./home/smart-contact/smart-contact.module').then(m => m.SmartContactModule),
                      },
                      {
                        path: 'flowbuilderlist',
                        loadChildren: () => import('./home/flow-builder/flowbuilderlist/flowbuilderlist.module').then(m => m.FlowbuilderlistModule),
                      },
                      {
                        path: 'editprofile',
                        loadChildren: () => import('./home/profile/profile.module').then(m => m.ProfileModule),
                      },
                      {
                        path: 'settings',
                        loadChildren: () => import('./home/settings/settings.module').then(m => m.SettingsModule),
                      },
                      {
                        path: 'setmessagelist',
                        loadChildren: () => import('./home/campaign/campaign.module').then(m => m.CampaignModule),
                      },
                      {
                        path: 'messagelist',
                        loadChildren: () => import('./home/campaign/campaign-list/campaign-list.module').then(m => m.CampaignListModule),
                      },
                      {
                        path: 'broadcastlist',
                        loadChildren: () => import('./home/broadcastmessage/broadcastlist/broadcastlist.module').then(m => m.BroadcastlistModule),
                      },
                      {
                        path: 'broadcastmessage',
                        loadChildren: () => import('./home/broadcastmessage/broadcastmessage.module').then(m => m.BroadcastmessageModule),
                      },
                      {
                        path: 'allowedusers',
                        loadChildren: () => import('./home/allowed-public/allowed-public.module').then(m => m.AllowedPublicModule),
                      },
                      {
                        path: 'categories',
                        loadChildren: () => import('./home/categories/categories.module').then(m => m.CategoriesModule),
                      },
                      {
                        path: 'virtualhuman',
                        loadChildren: () => import('./home/virtual-human/virtual-human.module').then(m => m.VirtualHumanModule),
                      },
                      {
                        path: 'variable',
                        loadChildren: () => import('./home/variable/variable/variable.module').then(m => m.VariableModule),
                      },
                      {
                        path: 'users',
                        loadChildren: () => import('./home/users/users.module').then(m => m.UsersModule),
                      },
                      {
                        path: 'groupconversations',
                        loadChildren: () => import('./home/group-conversation/group-conversation/group-conversation.module').then(m => m.GroupConversationModule),
                      },
                      {
                        path: 'livegroupconversations',
                        loadChildren: () => import('./home/live-group-conversation/live-group-conversation.module').then(m => m.LiveGroupConversationModule),
                      },
                      {
                        path: 'audittrial',
                        loadChildren: () => import('./home/audit-trial/audit-trial.module').then(m => m.AuditTrialModule),
                      },
                      {
                        path: 'flex',
                        loadChildren: () => import('./home/flex-messages/flex-messages.module').then(m => m.FlexMessagesModule),
                      },
                      {
                        path: 'listflex',
                        loadChildren: () => import('./home/flex-messages/list-flex-messages/list-flex-messages.module').then(m => m.ListFlexMessagesModule),
                      },

                      // { path: 'messagelist', component: CampaignListComponent, data: { title: 'Message List' } },
                   // { path: 'setmessagelist', component: CampaignComponent, data: { title: 'Set Message List' } },
                    // { path: 'broadcastmessage', component: BroadcastmessageComponent, data: { title: 'Broadcast' } },
                    // { path: 'broadcastlist', component: BroadcastlistComponent, data: { title: 'Broadcast List' } },
                    // { path: 'allowedusers', component: AllowedPublicComponent, data: { title: 'Allowed Users' } },
                    // { path: 'settings', component: SettingsComponent, data: { title: 'Settings' } },
                    // { path: 'editprofile', component: ProfileComponent, data: { title: 'Edit Profile' } },
                    // { path: 'flowbuilderlist', component: FlowbuilderlistComponent, data: { title: 'Flow Builder list' } },
                     // { path: 'smartcontact', component: SmartContactComponent, data: { title: 'SmartContact' } },
                    // { path: 'agentallocation', component: AgentAllocationComponent, data: { title: 'Agent Allocation' } },
                    // { path: 'reports', component: AnalyticsComponent, data: { title: 'Analytics' } },
                    // { path: 'contacts', component: ContactsComponent, data: { title: 'Contacts' } },
                     // { path: 'conversationalelements', component: QuestionComponent, data: { title: 'Content Element' } },
                      // { path: 'history', component: RecentConversationComponent, data: { title: 'Conversations' } },
                      { path: 'conversations/:id', component: ListComponent, data: { title: 'Conversations' } },
                    // { path: 'categories', component: CategoriesComponent, data: { title: 'Categories' } },
                    // { path: 'newagent', component: AddnewagentComponent, data: { title: 'Agent Allocation' } },
                    // { path: 'flow-builder', component: FlowBuilderComponent, data: { title: 'Flow Builder' } },
                    { path: 'flow-builderv3/:id', component: FlowbuilderV3Component, data: { title: 'Flow Builder' } },
                    { path: 'rag', component: RagComponent, data: { title: 'Rag' } },
                    { path: 'quickreply', component: QuickreplyComponent, data: { title: 'Quick Reply' } },
                    // { path: 'tags', component: TagsModule, data: { title: 'Tags' } },

                    {
                      path: 'tags',
                      loadChildren: () => import('./home/tags/tags.module').then(m => m.TagsModule),
                    },
                   // { path: 'notifications', component: TriggerNotificationComponent, data: { title: 'Trigger Notifications' } },
                   // { path: 'virtualhuman', component: VirtualHumanComponent, data: { title: 'VirtualHuman' } },
                    // { path: 'allowedapps', component: AppselectorComponent, data: { title: 'Allowed Apps' } }
                    { path: 'flexmessage', component: FlexBroadcastComponent, data: { title: 'Flex Message' },},
                    { path: 'richmenu', component: RichMenusComponent, data: { title: 'Rich Menu' },},
                    { path: 'richmenulist', component: RichmenuListComponent, data: { title: 'Rich Menu List' },},
                  ]
            },


            /*{
                path: 'flowbuilder-node',
                component: FlowbuildernodeComponent,
                data: { title: 'flowbuilder node' },
            },*/
            {
                path: 'login',
                component: LoginComponent,
                data: { title: 'Login' },
            },
            {
                path: '**',
                redirectTo: `${environment.dashboardPrefix}/overviews`,
                pathMatch: 'full'
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule],
    declarations: []
})
export class AppRoutingModule {
}
