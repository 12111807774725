import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  httpOptions: any;
  apiUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
    private readonly sharedService: SharedsService,
    private readonly expService: ExceptionHandlerService
  ) {
    this.apiUrl = this.envService.read('apiUrl');
    this.setHttpHeaders();
  }

  setHttpHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'access-token': this.sharedService.token,
      }),
    };
  }

  createTemplate(templateArgs): Observable<any> {
    this.httpOptions.params = new HttpParams();
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/savetemplate`,
        templateArgs,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  editTemplate(templateArgs): Observable<any> {
    this.httpOptions.params = new HttpParams();
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/updateTemplate`,
        templateArgs,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getAllTemplates(args): Observable<any> {
    args.appId = this.sharedService.appId;
    this.httpOptions.params = new HttpParams()
      .set('limit', args.limit)
      .set('search', args.search)
      .set('skip', args.skip)
      .set('sort', args.sort)
      .set('sortorder', args.sortorder)
      .set('user_id', args.user_id)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/dashboard/gettemplatelist`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  deleteTemplate(deleteTemplateArgs): Observable<any> {
    deleteTemplateArgs.appId = this.sharedService.appId;
    deleteTemplateArgs.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = new HttpParams();
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/deletetemplate`,
        deleteTemplateArgs,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  fileupload(args): Observable<any> {
        this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.envService.read('Authorization'),
            'access-token': this.sharedService.token,
          }),
        };
        this.httpOptions.params = null;
        args.appId = this.sharedService.appId;
        args.timeZone = this.sharedService.timeZone;
        return this.http
          .post<any>(
            `${this.apiUrl}/upload/chatbox/attachment`,
            args,
            this.httpOptions
          )
          .pipe(
            tap(),
            catchError((error) => {
              this.expService.handleError(error);
              return of(error);
            })
          );    }

  uploadMedia(formData): Observable<any> {
   const timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(`${this.apiUrl}/uploadmedia`, formData, timeZone)
      .pipe(
        tap(),
        catchError((error) => {
          return of(error);
        })
      );
  }

   fetchWabaFLows(arg): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('limit', arg?.limit)
      .set('skip', arg?.skip);
    return this.http
      .get<any>(`${this.apiUrl}/whatsApp/template/flows`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  saveFlexMessage(arg,action): Observable<any> {
    this.httpOptions.params = new HttpParams();
    const type = action === 'edit' ? 'update' :'save';
    console.log("arg111", arg)
    // arg.flexJson = JSON.parse(arg.flexJson)
    return this.http
      .post<any>(
        `${this.apiUrl}/flex-message/`+type,
        arg,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  createLineTemplate(templateArgs): Observable<any> {
    this.httpOptions.params = new HttpParams();
    templateArgs['appid'] = this.sharedService.appId;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/line/savetemplate`,
        templateArgs,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  fetchFlexMessageList(args): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('search',args.search)
      .set('limit', args.limit)
      .set('skip', args.skip);
    return this.http
      .get<any>(`${this.apiUrl}/flex-message/list`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  getAllLineTemplates(args): Observable<any> {
    args.appId = this.sharedService.appId;
    this.httpOptions.params = new HttpParams()
      .set('limit', args.limit)
      .set('search', args.search)
      .set('skip', args.skip)
      .set('sort', args.sort)
      .set('sortorder', args.sortorder)
      .set('user_id', args.user_id)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/dashboard/line/gettemplatelist`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  deleteLineTemplate(deleteTemplateArgs): Observable<any> {
    deleteTemplateArgs.appId = this.sharedService.appId;
    deleteTemplateArgs.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = new HttpParams();
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/line/deletetemplate`,
        deleteTemplateArgs,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  editLineTemplate(templateArgs): Observable<any> {
    this.httpOptions.params = new HttpParams();
    templateArgs['appid'] = this.sharedService.appId;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/line/updateTemplate`,
        templateArgs,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
}
