import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConversationService } from '../conversation/conversation.service';
import { LayoutAreas } from '../image-layout/layout-areas';
import { MatDialog } from '@angular/material/dialog';
import { RichMenuService } from './rich-menu.service';
import { environment } from 'src/environments/environment';
type LayoutId = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
interface LayoutAreaData {
  areaId: number;
  text?: string;
  isUrl?: boolean;
  url?: string;
  styles: { [key: string]: string | number };
}
@Component({
  selector: 'app-rich-menus',
  templateUrl: './rich-menus.component.html',
  styleUrls: ['./rich-menus.component.scss']
})

export class RichMenusComponent implements OnInit {
  richName: any = '';
  action: any = 'open';
  richForm = {
    richName: '',
    displayName: '',
    displayMode: 'hide',
    layoutSize: 'large'
  }
  // submitted: any = false;
  // flexjson: any = '';
  translate: TranslateService;
  translatorLanguage: any;
  errorList: any = {};
  // validationStatus: any = false;
  // templateService: TemplateService;
  toastr: ToastrService;
  router: Router;
  loaderShow = false;
  // sharedService: SharedsService;
  // editElement: any = null;
  screenWidth: number;
  // infoUrl = "https://developers.line.biz/flex-simulator";
  leftMargin = '270px';
  uploadloader: boolean;
  converstaionService: ConversationService;
  image_url: any;
  image_url_org: any;
  @ViewChild('fileInput') fileInput: ElementRef;
  selectedLayoutId: LayoutId = 5;
  layoutAreaOptions: Array<LayoutAreaData> = LayoutAreas()[this.selectedLayoutId];
  selectedLayoutArea: LayoutAreaData | null;
  richData: {};
  selectedArea: { width: number; height: number; };
  @ViewChild('imageContainer') imageContainer!: ElementRef;
  selectedPosition: { x: number; y: number; };
  vewImageSize: { width: number; height: number; };
  lineImageMap: boolean;
  imageDimensions: { width: number; height: number; };
  uploadloaderImage: boolean;
  uploadloaderBase: boolean;
  validationStatus: boolean;
  disableImagemap: boolean;
  user_id: any;
  selectedMenu: any;
  constructor(private readonly injector: Injector, public dialog: MatDialog,
    private readonly richMenuService: RichMenuService) {
    this.translate = this.injector.get<TranslateService>(TranslateService);
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
    this.toastr = this.injector.get<ToastrService>(ToastrService);
    this.router = this.injector.get<Router>(Router);
    // this.sharedService = this.injector.get<SharedsService>(SharedsService);
    this.converstaionService = this.injector.get<ConversationService>(ConversationService);
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.action  = this.router.getCurrentNavigation().extras.state.action;
      this.selectedMenu = this.router.getCurrentNavigation().extras.state.data;
      this.disableImagemap = true;
    }
  }
  ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
    this.user_id = user.user_id ? user.user_id : '';
    if(this.action === 'edit' && this.selectedMenu) {
      this.image_url_org = this.selectedMenu.image_url;
      this.image_url = this.selectedMenu.image_url;
      this.richForm.richName = this.selectedMenu.name;
      this.richForm.displayName = this.selectedMenu.button_label;
      this.layoutAreaOptions = this.selectedMenu.layoutArea;
      this.selectedLayoutId = this.selectedMenu.layoutId
      this.richForm.displayMode = this.selectedMenu.component?.selected === true ? 'display' : 'hide';
    }
  }
  initResponsiveElements() {
    if (window.screen.width < 750) {
      return false;
    } else {
      return true;
    }
  }
  handleFileUpload(event, action) {
    const Editfiles = event.target.files;
    const file = Editfiles[0];
    const file2 = Editfiles[0]?.name;
    const pattern1 = /.*jpeg.*/; // NOSONAR
    const pattern2 = /.*png.*/; // NOSONAR
    const pattern3 = /video-*/;
    const reader = new FileReader();
    const sizeinMb = (file.size / (1024 * 1024)).toFixed(2);
    this.setUploaderOn(action);
    if (!file.type.match(pattern1) && !file.type.match(pattern2) && action !== 'video' || (!file.type.match(pattern3) && action === 'video')) {
      this.toastr.error('', this.translate.instant('toastr.invalid_format'));
      this.setfileInput();
      this.uploadloader = false;
      this.uploadloaderImage = false;
      this.uploadloaderBase = false;
      return;
    }

    if (parseFloat(sizeinMb) > 1 && action === 'base') {
      this.toastr.error('', this.translate.instant('toastr.uploadedFile_limit_1mb'));
      this.setfileInput();
      this.uploadloaderBase = false;
      return;
    }
    reader.readAsBinaryString(file);
    reader.onload = (onloadEvent: any) => {
      const Data = onloadEvent.target.result;
      const contentData = btoa(Data);
      const args = {
        data: contentData,
        contentType: file.type,
        filename: file2 || '',
        bucketType: 'content'
      };
      this.converstaionService.fileupload(args).subscribe(response => {
        this.fileuploadResponse(response, action);
      },
        err => {
        });
    };
  }
  setUploaderOn(action) {
    if (action === 'video') {
      this.uploadloader = true;
    }
    if (action === 'image') {
      this.uploadloaderImage = true;
    }
    if (action === 'base') {
      this.uploadloaderBase = true;
    }
  }
  fileuploadResponse(response, action) {
    this.setfileInput();
    if (response.info?.url) {
      if (action === 'video') {
        this.uploadloader = false;
        this.selectedLayoutArea['videoUrl'] = response.info?.url;
        this.selectedLayoutArea['videoUrlOrginal'] = response.info?.url_orginal || response.info?.url;
        this.selectedLayoutArea['filename'] = this.getfilenameFromURL(this.selectedLayoutArea['videoUrlOrginal']);
      }
      if (action === 'image') {
        this.uploadloaderImage = false;
        this.selectedLayoutArea['previewImage'] = response.info?.url;
        this.selectedLayoutArea['previewImageOrginal'] = response.info?.url_orginal || response.info?.url;
        this.selectedLayoutArea['previewImageName'] = this.getfilenameFromURL(this.selectedLayoutArea['previewImageOrginal']);
      }
      if (action === 'base') {
        this.uploadloaderBase = false;
        this.image_url = response.info.url;
        this.image_url_org = response.info.url_orginal;
      }
    } else {
      this.toastr.info('', response.info);
    }
  }
  getfilenameFromURL(url) {
    const match = url?.match(/\/(\d+)$/);
    return match ? match[1] : null;
  }
  setfileInput() {
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }
  onLayoutSelect(layoutId: LayoutId) {
    this.selectedLayoutId = layoutId;
    this.layoutAreaOptions = LayoutAreas()[layoutId];
    this.selectedLayoutArea = null;
  }
  selectLayoutArea(area: any, layoutSection: HTMLElement, imageDiv: HTMLElement, event: MouseEvent) {
    this.selectedLayoutArea = area;
    if (!this.selectedLayoutArea['action']) {
      this.selectedLayoutArea['action'] = 'postback';
    }
    // Get the position of the selected layout area relative to the viewport
    const layoutRect = layoutSection.getBoundingClientRect();
    const imageLayout = imageDiv.getBoundingClientRect();
    // Get the position of the image container relative to the viewport
    const containerRect = this.imageContainer.nativeElement.getBoundingClientRect();
    // Calculate the X and Y coordinates relative to the image container
    const x = layoutRect.left - containerRect.left;
    const y = layoutRect.top - containerRect.top;
    const width = layoutRect.width;
    const height = layoutRect.height;
    const widthImg = imageLayout.width;
    const heightImg = imageLayout.height;
    this.selectedArea = {
      width: Math.round(width),
      height: Math.round(height)
    }
    this.selectedPosition = {
      x: Math.round(x),
      y: Math.round(y)
    }
    this.vewImageSize = {
      width: Math.round(widthImg),
      height: Math.round(heightImg)
    }
  }
  onImageLoad(event: Event): void {
    this.disableImagemap = false;
    const image = event.target as HTMLImageElement;
    this.imageDimensions = {
      width: Math.round(image.naturalWidth),
      height: Math.round(image.naturalHeight),
    };
   const valid = this.checkValidDimensions();
    if (!valid) {
      this.disableImagemap = true;
      this.toastr.error('', this.translate.instant('richMenu.attachment_info'));
    }
  }
  checkValidDimensions() {
    const width = this.imageDimensions.width;
    const height = this.imageDimensions.height;
    if ((width === 2500 && height >= 1600 && height < 1700) || (width === 1200 && height >= 800 && height < 820) || (width === 800 && height >= 500 && height < 550)) {
      return true;
    } else {
      return false;
    }

  }
  onActionSelect(e: string, index) {
    this.selectedLayoutArea['action'] = e;
    this.selectedLayoutArea.isUrl = (e === 'url');
    if (!this.selectedLayoutArea.isUrl) {
      this.selectedLayoutArea.url = '';
    }
  }
  updateLayoutData() {
    this.selectedLayoutArea['area'] = this.imagePositionCalulate();
    let valid = true;
    if (this.selectedLayoutArea['action'] === 'video') {
      if (!this.selectedLayoutArea['videoUrl']) {
        this.toastr.error('', this.translate.instant('toastr.uploadVideo'));
        valid = false;
      } else if (!this.selectedLayoutArea['previewImage']) {
        this.toastr.error('', this.translate.instant('toastr.uploadpreviewImage'));
        valid = false;
      }
    }
    if (this.selectedLayoutArea['action'] === 'postback') {
      if (!this.selectedLayoutArea['text']) {
        this.toastr.error('', this.translate.instant('content_elements.button_text_is_required'));
        valid = false;
      }
    }
    if (this.selectedLayoutArea['action'] === 'uri') {
      if (!this.selectedLayoutArea['linkUri']) {
        this.toastr.error('', this.translate.instant('toastr.provide_URL'));
        valid = false;
      }
    }
    if (valid) {
      this.selectedLayoutArea['isValidate'] = true;
      this.selectedLayoutArea = null;
    }
  }
  imagePositionCalulate() {
    const selectedArea = {
      x: this.pixalRatio(this.selectedPosition?.x, this.vewImageSize?.width, this.imageDimensions?.width),
      y: this.pixalRatio(this.selectedPosition?.y, this.vewImageSize?.height, this.imageDimensions?.height),
      width: this.pixalRatio(this.selectedArea?.width, this.vewImageSize?.width, this.imageDimensions?.width),
      height: this.pixalRatio(this.selectedArea?.height, this.vewImageSize?.height, this.imageDimensions?.height)
    }
    return selectedArea;
  }
  pixalRatio(position, viewSize, imgSize) {
    return Math.floor((position / viewSize) * imgSize);
  }
  onSubmit() {
    this.loaderShow = true;
    this.validationStatus = true;
    if (!this.richForm.richName) {
      this.errorList['richName'] = 'Name is required.';
      this.validationStatus = false;
    }
    if (!this.richForm.displayName) {
      this.errorList['displayName'] = 'Display menu text is required.';
      this.validationStatus = false;
    }
    if (!this.validationStatus) {
      this.loaderShow = false;
      return;
    }
    const component = this.updateImageMap();
    let arg = {
      image_url: this.image_url_org,
      name: this.richForm.richName?.trim(),
      button_label: this.richForm.displayName,
      component: component,
      user_id: this.user_id,
      layoutAreaOptions: this.layoutAreaOptions,
      layoutId: this.selectedLayoutId
    };
    this.richMenuService.addRichMenu(arg).subscribe(
      (response) => {
        if (response.status === 200) {
          this.toastr.success('', response.info);
          this.router.navigate([environment.dashboardPrefix + '/richmenulist']);
        } else {
          this.toastr.error('', response?.info ?? response?.error?.message);
        }
        this.loaderShow = false;
      },
      (err) => {
        this.toastr.error('', this.translate.instant('toastr.error_adding_contact'));
      }
    );
  }

  updateImageMap() {
    const isvalid = this.layoutAreaOptions?.some(item => item['isValidate']);
    if (!isvalid) {
      return;
    }
    let lineMessage = {
      size: this.imageDimensions,
      selected: this.richForm.displayMode === 'display' ? true : false,
    };
    let video;
    let actions = [];
    this.layoutAreaOptions.forEach(item => {
      if (item['isValidate']) {
        if (item['action'] === 'video' && item['videoUrl']) {
          video = {
            originalContentUrl: item['videoUrlOrginal'],
            previewImageUrl: item['previewImageOrginal'],
            area: item['area'],
          };
          lineMessage['video'] = video;
        } else {
          let btnAction = { type: item['action'] }
          if (item['action'] === 'uri') {
            btnAction['uri'] = item['linkUri'];
            btnAction['label'] = '';
          }
          if (item['action'] === 'postback') {
            btnAction['displayText'] = item['text'];
            btnAction['data'] = item['text'];
            btnAction['label'] = item['label'];
          }
          const action = {
            bounds: item['area'],
            action: btnAction
          };

          actions?.push(action);
        }
      }
    })
    lineMessage['areas'] = actions;
    return lineMessage;
  }
  onCancel() {
    this.router.navigate([environment.dashboardPrefix + '/richmenulist']);
  }
  clearField() {
    const index = this.layoutAreaOptions.findIndex(item => item.areaId === this.selectedLayoutArea?.areaId);
    if(index > -1) {
      const layout = LayoutAreas()[this.selectedLayoutId];
      this.layoutAreaOptions[index] = layout[index]
    }
    this.selectedLayoutArea = null;
  }
}
