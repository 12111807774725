import { Component, OnDestroy, Input, AfterViewInit, OnChanges, SimpleChanges , Injector} from '@angular/core';
import { AmChart, AmChartsService } from '@amcharts/amcharts3-angular';
import { IconsService } from '../icons.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnDestroy, AfterViewInit, OnChanges {
  chart: AmChart;
  viewInit = false;
  @Input() data;
  @Input() graphHeight: any = 350;
  id: any;
  hoursGraphVar: any = [];
  flowGraphVar: any = [];
  templateBroadcastvar: any = [];
  agentFlowVar: any = [];
  csvFile: any;
  csvFile1: any;
  apiCountDataArray: any;
  nonAggDataArray: any = [];
  nonAggAgentData: any = [];
  iconsService: IconsService;

  constructor(private readonly AmCharts: AmChartsService, private readonly injector: Injector) {
    const crypto = window.crypto;
    const arrays = new Uint32Array(1);
    this.id = crypto.getRandomValues(arrays).toString().substring(4);
    this.iconsService = this.injector.get<IconsService>(IconsService);
  }
  themeStyles = {
    'orange-style.css': { backgroundColor: '#FFD078', rootColor: '#e6a03f' },
    'green-style.css': { backgroundColor: '#CCFBCC', rootColor: 'green' },
     'light-green-style.css': { backgroundColor: '#CCFFF5', rootColor: '#3bbd9b' },
     'blue-style.css': { backgroundColor: '#DBEDFF', rootColor: '#57a6f4' },
     'black-style.css': { backgroundColor: '#BEBEBE', rootColor: '#3B3B3B' },
   };
  theme = localStorage.getItem('theme');
  oldTheme = this.themeStyles[this.theme];
  largeTitleGraph: any = ['trendingArticles', 'flowNodes'];
  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && this.viewInit) {
      this.makeChart();
    }
  }

  ngAfterViewInit() {
    this.makeChart();
    this.viewInit = true;
  }

  makeChart() {
    switch (this.data.name) {
      case 'graphDate':
        this.handleGraphDate();
        break;

      case 'userDate':
        this.handleUserDate();
        break;

      case 'activityHour':
        this.handleActivityHour();
        break;

      case 'activityDay':
        this.handleActivityDay();
        break;

      case 'overviewGraph':
        this.handleOverviewGraph();
        break;

      case 'UserCount':
        this.handleUserCountGraph();
        break;

      case 'graphTrending':
        this.handleGraphTrending();
        break;

      case 'overviewGraph4':
        this.handleOverviewGraph4();
        break;

      case 'overviewGraph5':
        this.handleOverviewGraph5();
        break;

      case 'overviewGraph6':
        this.handleOverviewGraph6();
        break;

      case 'categoryCount':
        this.handleCategoryCount();
        break;

      case 'activityFlow':
        this.handleFlow();
        break;

      case 'ApiUsageCount':
        this.handleApiCount();
        break;

      case 'broadcast':
      case 'liveAgentChatGraph':
        this.doubleBarGraph();
        break;

      case 'trendingArticles':
      case 'flowNodes':
      case 'agiMindspaceUsage':
        this.singleBarGraph();
        break;

      case 'templateBroadcastGraph':
        this.templateBroadcastGraph();
        break;

      case 'variableAPIUsage':
        this.singleBarGraph();
        break;

      case 'agentFlowAnalysisGraph':
        this.agentFlowAnalysisGraph();
        break;

      default:
        console.warn('Unknown chart type:', this.data.name);
        break;
    }
  }

  handleCategoryCount() {
    const categoryCount = [];
    for (const graphdata of this.data.widgets) {
      const data1 = {
        'balloonText': '[[title]]<span style=\'font-size:12px\'>: [[value]]</span>',
        'fillAlphas': 0.8,
        'labelPosition': 'middle',
        'labelRotation': 270,
        'lineAlpha': 0.3,
        'title': graphdata.name,
        'type': 'column',
        'color': '#000000',
        'valueField': graphdata.name,
        'fillColors': graphdata.color,
        'fixedColumnWidth': 50
      };
      categoryCount.push(data1);
    }
    if (this.data.colorvalue === 'all') {
      this.chart = this.AmCharts.makeChart(this.id, {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'legend': {
          'position': 'top',
          'useGraphSettings': true,
          'markerSize': 10
        },
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': [{
          'stackType': 'regular',
          'axisAlpha': 0.3,
          'gridAlpha': 0,
          'totalText': '[[total]]'
        }],
        'graphs': categoryCount,
        'categoryField': 'name',
        'categoryAxis': {
          'gridPosition': 'start',
          'labelRotation': 90,
          'gridAlpha': 0.2,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'export':
        {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        }
      });
    } else {
      this.chart = this.AmCharts.makeChart(this.id, {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'valueAxes': [{
          'gridAlpha': 0.2,
          'dashLength': 0
        }],
        'gridAboveGraphs': false,
        'startDuration': 1,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': 'count',
          'fillColors': '',
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false
        },
        'categoryField': '_id',
        'categoryAxis': {
          'gridPosition': 'start',
          'gridAlpha': 0.2,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export':
        {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        }
        ,
        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
      });
    }
  }
  handleOverviewGraph6() {
    this.chart = this.AmCharts.makeChart(this.id,
      {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': [{
          'gridAlpha': 0.2,
          'dashLength': 0,
          'minimum': 0
        }],
        'gridAboveGraphs': false,
        'startDuration': 1,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': 'count',
          'fillColors': ['#333333', '#8c8c8c']
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false
        },
        'categoryField': 'Category',
        'categoryAxis': {
          'gridPosition': 'start',
          'labelRotation': 90,
          'gridAlpha': 0.2,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export': {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        },

        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips

      });
  }
  handleOverviewGraph5() {
    this.chart = this.AmCharts.makeChart(this.id,
      {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': [{
          'gridAlpha': 0.2,
          'dashLength': 0,
          'minimum': 0
        }],
        'gridAboveGraphs': false,
        'startDuration': 1,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': 'No. of Hours',
          'fillColors': [ this.oldTheme?.rootColor, this.oldTheme?.backgroundColor]
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false
        },
        'categoryField': 'Date',
        'categoryAxis': {
          'gridPosition': 'start',
          'labelRotation': 90,
          'gridAlpha': 0.2,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export': {
          'enabled': true,
          'processData': function (data, cfg) {
            if (cfg.extension === 'csv') {
              return data[0]['Agent name'].map(function (infoExport1) {
                return {
                  ['Agent name']: infoExport1.agent,
                  Date: infoExport1._id,
                  ['No. of Hours']: infoExport1.count
                };

              });
            }
            return data;
          },
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        },
        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
      });
  }
  handleOverviewGraph4() {
    this.chart = this.AmCharts.makeChart(this.id,
      {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': [{
          'gridAlpha': 0.2,
          'dashLength': 0,
          'minimum': 0
        }],
        'gridAboveGraphs': false,
        'startDuration': 1,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': 'No. of Chats',
          'fillColors': [ this.oldTheme?.rootColor, this.oldTheme?.backgroundColor]
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false
        },
        'categoryField': 'Date',
        'categoryAxis': {
          'gridPosition': 'start',
          'labelRotation': 90,
          'gridAlpha': 0.2,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export': {
          'enabled': true,
          'processData': function (data, cfg) {
            if (cfg.extension === 'csv') {
              return data[0]['Agent name'].map(function (infoExport) {
                return {
                  ['Agent name']: infoExport.agent,
                  Date: infoExport._id,
                  ['No. of Chats']: infoExport.count
                };

              });
            }
            return data;
          },
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        },
        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
      });
  }
  handleGraphTrending() {
    this.chart = this.AmCharts.makeChart(this.id,
      {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': [{
          'gridAlpha': 0.2,
          'dashLength': 0,
          'minumum': 0
        }],
        'gridAboveGraphs': false,
        'startDuration': 1,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': 'sessionsCount',
          'fillColors': ['#333333', '#8c8c8c']
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false
        },
        'categoryField': 'category',
        'categoryAxis': {
          'gridPosition': 'start',
          'labelRotation': 90,
          'inside': false,
          'gridAlpha': 0.2,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export': {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        },

        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grip

      });
  }
  handleUserCountGraph() {
    const colorvalue = [this.oldTheme?.rootColor, this.oldTheme?.backgroundColor];
    this.chart = this.AmCharts.makeChart(this.id, {
      'hideCredits': true,
      'type': 'serial',
      'theme': 'light',
      'lineColor': '#CC0000',

      'dataProvider': this.data?.graph ? this.data.graph : [],
      'titles': [
        {
          'text': this.data.header,
          'size': 15
        }
      ],
      'valueAxes': [{
        'gridAlpha': 0.2,
        'dashLength': 0,
        'minimum': 0
      }],
      'gridAboveGraphs': false,
      'startDuration': 1,
      'graphs': [{
        'balloonText': '[[category]]: <b>[[value]]</b>',
        'fillAlphas': 1,
        'lineAlpha': 0,
        'type': 'column',
        'valueField': 'No. of users',
        'fillColors': colorvalue,
      }],
      'chartCursor': {
        'categoryBalloonEnabled': false,
        'cursorAlpha': 0,
        'zoomable': false
      },
      'categoryField': 'Date',
      'categoryAxis': {
        'gridPosition': 'start',
        'labelRotation': 90,
        'gridAlpha': 0.2,
        'tickPosition': 'start',
        'tickLength': 0.2
      },
      'chartScrollbar': {
        'updateOnReleaseOnly': true,
        'enabled': true,
      },
      'export': {
        'enabled': true,
        'processData': function (data, cfg) {
          if (cfg.extension === 'csv' && data[0].Channel !== undefined) {
            return data[0].Channel.map(function (item) {
              return {
                Channel: item.Channels,
                Date: item.Date,
                ['No. of users']: item.count
              };

            });
          }
          return data;
        },
        'fileName': this.data.fileName,
        'menu': [{
          'class': 'export-main',
          'menu': [{
            'label': 'Download',
            'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
          }]
        }],
        'pageOrigin': false,
      },

      'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
    });
  }
  handleOverviewGraph() {
    this.chart = this.AmCharts.makeChart(this.id,
      {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': [{
          'gridAlpha': 0.2,
          'dashLength': 0,
          'minumum': 0
        }],
        'gridAboveGraphs': false,
        'startDuration': 1,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': 'No. of messages',
          'fillColors': [ this.oldTheme?.rootColor, this.oldTheme?.backgroundColor]
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false
        },
        'categoryField': 'Date',
        'categoryAxis': {
          'gridPosition': 'start',
          'labelRotation': 90,
          'gridAlpha': 0.2,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export': {
          'enabled': true,
          'processData': function (data, cfg) {
            if (cfg.extension === 'csv' && data[0].Channel !== undefined) {
              return data[0].Channel.map(function (item) {
                return {
                  Channel: item.Channels,
                  Date: item.Date,
                  ['No. of messages']: item.count
                };

              });
            }
            return data;
          },
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        },

        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
      });
  }
  handleActivityDay() {
    this.chart = this.AmCharts.makeChart(this.id,
      {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'valueAxes': [{
          'gridAlpha': 0.2,
          'dashLength': 0,
          'minumum': 0
        }],
        'gridAboveGraphs': false,
        'startDuration': 0,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': 'count',
          'fillColors': ['#333333', '#8c8c8c']
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false
        },
        'categoryField': '_id',
        'categoryAxis': {
          'parseDates': true,
          'gridPosition': 'start',
          'labelRotation': 90,
          'gridAlpha': 0.2,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export': {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        },
        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
      });
  }
  handleActivityHour() {
    this.hoursGraphVar = [];
    let colorvalue = [];
    if (this.data.colorvalue === 'whatsapp' || this.data.colorvalue === 'WhatsApp') {
      colorvalue = ['#0d7f60'];
    } else {
      colorvalue = ['#1E87F0', '#40BCF9'];
    }
    for (const graphdata of this.data.widgets) {
      const data1 = {
        'balloonText': '[[title]]<span style=\'font-size:12px\'>: [[value]]</span>',
        'fillAlphas': 0.8,
        'labelPosition': 'middle',
        'labelRotation': 270,
        'lineAlpha': 0.3,
        'title': graphdata._id,
        'type': 'column',
        'color': '#000000',
        'valueField': graphdata._id,
        'fillColors': graphdata.color,
        'fixedColumnWidth': 40
      };
      this.hoursGraphVar.push(data1);
    }
    if (this.data.colorvalue === 'all') {
      this.chart = this.AmCharts.makeChart(this.id, {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'legend': {
          'position': 'top',
          'useGraphSettings': true,
          'markerSize': 10
        },
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': [{
          'stackType': 'regular',
          'axisAlpha': 0.3,
          'gridAlpha': 0,
          'totalText': '[[total]]'
        }],
        'graphs': this.hoursGraphVar,
        'categoryField': 'Hour',
        'categoryAxis': {
          'gridPosition': 'start',
          'axisAlpha': 0,
          'gridAlpha': 0,
          'position': 'left',
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'export':
        {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        }
      });
    } else {
      this.chart = this.AmCharts.makeChart(this.id, {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'valueAxes': [{
          'gridAlpha': 0.2,
          'dashLength': 0
        }],
        'gridAboveGraphs': false,
        'startDuration': 1,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': 'count',
          'fillColors': colorvalue,
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false
        },
        'categoryField': '_id',
        'categoryAxis': {
          'gridPosition': 'start',
          'gridAlpha': 0.2,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export':
        {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        }
        ,
        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
      });
    }
  }

  handleUserDate() {
    this.chart = this.AmCharts.makeChart(this.id, {
      'hideCredits': true,
      'type': 'serial',
      'theme': 'light',
      'lineColor': '#CC0000',
      'dataDateFormat': 'MM-DD-YYYY',
      'dataProvider': this.data?.graph ? this.data.graph : [],
      'valueAxes': [{
        'gridAlpha': 0,
        'position': 'left',
        'minumum': 0

      }],
      'gridAboveGraphs': false,
      'startDuration': 0,
      'graphs': [{
        'balloonText': 'Count: <b>[[value]]</b>',
        'fillAlphas': 1,
        'lineAlpha': 0.1,
        'type': 'column',
        'valueField': 'count',
        'fillColors': ['#333333', '#8c8c8c']
      }],
      'chartCursor': {
        'categoryBalloonEnabled': false,
        'cursorAlpha': 0,
        'zoomable': false
      },
      'categoryField': '_id',

      'categoryAxis': {
        'parseDates': false,
        'gridPosition': 'start',
        'dashLength': 1,
        'gridAlpha': 0.2,
        'tickPosition': 'start',
        'tickLength': 0.2,
        'title': 'Rating',
        'position': 'bottom'
      },
      'chartScrollbar': {
        'updateOnReleaseOnly': true,
        'enabled': true,
      },
      'export': {
        'enabled': true,
        'fileName': this.data.fileName,
        'menu': [{
          'class': 'export-main',
          'menu': [{
            'label': 'Download',
            'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
          }]
        }], // Shown menu when entering the annotation mode
        'pageOrigin': false,
      },

      'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
    });
  }
  handleGraphDate() {
    console.log('handleGraphDate');
    this.chart = this.AmCharts.makeChart(this.id, {
      'hideCredits': true,
      'type': 'serial',
      'theme': 'light',
      'dataProvider': this.data?.graph ? this.data.graph : [],
      'dataDateFormat': this.data.dateFormat ? this.data.dateFormat : '',
      'valueAxes': [{
        'gridColor': '#8CA0B3',
        'gridAlpha': 0.2,
        'dashLength': 0,
        'minumum': 0,
        'title': this.data.valueTitle ? this.data.valueTitle : ''
      }],
      'gridAboveGraphs': true,
      'startDuration': 0,
      'graphs': [{
        'balloonText': '[[category]]: <b>[[value]]</b>',
        'fillAlphas': 1,
        'lineAlpha': 0,
        'type': 'column',
        'valueField': this.data.value,
        'labelText': this.data.showLabel ? `[[${this.data.value}]]` : '',
        'labelRotation': this.data.graph.length > 20 ? 270 : 0,
        'fillColors': ['#333333', '#8c8c8c']
      }],
      'chartCursor': {
        'categoryBalloonEnabled': false,
        'cursorAlpha': 0,
        'zoomable': false,
        'limitToGraph': 'id'
      },
      'categoryField': this.data.title,
      'categoryAxis': {
        'parseDates': !!this.data.date,
        'gridPosition': 'start',
        'gridColor': '#8CA0B3',
        'gridAlpha': 0.2,
        'tickPosition': 'start',
        'tickLength': 20
      },
      'export': {
        'enabled': false,
        'fileName': this.data.fileName,
        'menu': [{
          'class': 'export-main',
          'menu': [{
            'label': 'Download',
            'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
          }]
        }], // Shown menu when entering the annotation mode
        'pageOrigin': false,
      },
      'mouseWheelZoomEnabled': true,
      'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/',

    });

    this.chart?.exporting?.menu.events.on('contentcreated', function(event) {
      const content = event.content;
      const regex = /Saved from:.+\n/;  // Regex to match the "Saved from:" text
      event.content = content.replace(regex, ''); // Remove the text
    });
    if (this.data.graph.length > 31) {
      this.AmCharts.updateChart(this.chart, () => {
        // Change whatever properties you want
        this.chart.chartScrollbar = {
          'autoGridCount': true,
          'graph': 'id',
          'scrollbarHeight': 30
        };
      });
      this.chart.addListener('rendered', this.zoomChart());
    }
  }
  zoomChart() {
    // different zoom methods can be used - zoomToIndexes, zoomToDates, zoomToCategoryValues
    this.chart.zoomToIndexes(this.data.graph.length - 40, this.data.graph.length - 1);
  }

  ngOnDestroy() {
    if (this.chart) {
      this.AmCharts.destroyChart(this.chart);
    }
  }

  handleApiCount() {
    this.chart = this.AmCharts.makeChart(this.id, {
      'hideCredits': true,
      'type': 'serial',
      'theme': 'light',
      'lineColor': '#CC0000',
      'dataDateFormat': 'MM-DD-YYYY',
      'dataProvider': this.data?.graph ? this.data.graph : [],
      'valueAxes': [{
        'gridAlpha': 0,
        'position': 'left',
        'minumum': 0

      }],
      'gridAboveGraphs': false,
      'startDuration': 0,
      'graphs': [{
        'balloonText': 'Count: <b>[[value]]</b>',
        'fillAlphas': 1,
        'lineAlpha': 0.1,
        'type': 'column',
        'valueField': this.data.value,
        'fillColors': ['#333333', '#8c8c8c']
      }],
      'chartCursor': {
        'categoryBalloonEnabled': false,
        'cursorAlpha': 0,
        'zoomable': false
      },
      'categoryField': 'Date',
      'categoryAxis': {
        'parseDates': false,
        'gridPosition': 'start',
        'dashLength': 1,
        'gridAlpha': 0.2,
        'tickPosition': 'start',
        'tickLength': 0.2,
        'position': 'bottom',
        'labelRotation': 90
      },
      'chartScrollbar': {
        'updateOnReleaseOnly': true,
        'enabled': true,
      },
      'export': {
        'enabled': true,
        'processData':  (data, cfg) => {
            if (cfg.extension === 'csv' && data.length > 0) {
              this.apiCountDataArray = [];
              this.data?.csvData?.forEach((item) => {
                const temp = {};
                temp['Date'] = item?.date;
                temp['API Url'] = item?.apiUrl;
                temp['Count'] = item?.count;
                temp['Flow Name'] = item?.flow_name ?? '';
                this.apiCountDataArray.push(temp);
                item?.channels.forEach((ch) => {
                  const channel = this.iconsService.applyChannelNameForDropDown(ch?.connection_type);
                  temp[channel?.['name']] = ch.count;
                });
              });
              return this.apiCountDataArray;
            }
          },
        'fileName': this.data.fileName,
        'menu': [{
          'class': 'export-main',
          'menu': [{
            'label': 'Download',
            'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
          }]
        }], // Shown menu when entering the annotation mode
      },

      'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
    });
  }

  handleFlow() {
    this.flowGraphVar = [];
    const colorvalue = [];
    for (const graphdata of this.data.widgets) {
      const data1 = {
        'balloonText': `[[title]]<span style='font-size:12px'>: [[value]]</span>`,
        'fillAlphas': 0.8,
        'labelPosition': 'middle',
        'labelRotation': 90,
        'lineAlpha': 0.3,
        'title': graphdata,
        'type': 'column',
        'color': '#000000',
        'valueField': graphdata,
        'fillColors': graphdata.color,
        'fixedColumnWidth': 40
      };
      this.flowGraphVar.push(data1);
    }

    if (this.data.colorvalue === 'all') {
      this.chart = this.AmCharts.makeChart(this.id, {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'legend': {
          'position': 'top',
          'useGraphSettings': true,
          'markerSize': 10
        },
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': [{
          'stackType': 'regular',
          'axisAlpha': 0.3,
          'gridAlpha': 0,
        }],
        'graphs': this.flowGraphVar,
        'categoryField': 'Date',
        'categoryAxis': {
          'gridPosition': 'start',
          'axisAlpha': 0.2,
          'gridAlpha': 0,
          'position': 'left',
          'tickPosition': 'start',
          'tickLength': 0.2,
          'labelRotation': 90 // Rotate the date labels by 90 degrees
        },
        'export': {
          'enabled': true,
          'processData':  (data, cfg) => {
            if (cfg.extension === 'csv' && data[0]['Flow Name'] !== undefined) {
              this.nonAggDataArray = [];
              this.data?.allData?.forEach((item) => {
                const temp = {};
                temp['Date'] = item?.date;
                temp['Consumption'] = item?.totalConsumption;
                temp['Completed'] = item?.flowCompletedCount;
                temp['Transferred To Agent'] = item?.transferedToAgent;
                temp['Flow Name'] = item?.flowName;
                this.nonAggDataArray.push(temp);
              });
              return this.nonAggDataArray;
            }
          },
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }],
          'pageOrigin': false,
        }
      });
      this.chart?.exporting?.menu.events.on('contentcreated', function (event) {
        const content = event.content;
        const regex = /Saved from:.+\n/;  // Regex to match the "Saved from:" text
        event.content = content.replace(regex, ''); // Remove the text
      });
    } else {
      this.chart = this.AmCharts.makeChart(this.id, {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'valueAxes': [{
          'gridAlpha': 0.2,
          'dashLength': 0
        }],
        'gridAboveGraphs': false,
        'startDuration': 1,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': 'count',
          'fillColors': colorvalue,
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false
        },
        'categoryField': '_id',
        'categoryAxis': {
          'gridPosition': 'start',
          'gridAlpha': 0.2,
          'tickPosition': 'start',
          'tickLength': 0.2,
          'labelRotation': 90 // Rotate the date labels by 90 degrees
        },
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export': {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        },
        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
      });
    }

  }

  doubleBarGraph() {
    let axis =  [];
    if (this.data.name === 'liveAgentChatGraph') {
      axis = [{
        'stackType': 'regular',
        'axisAlpha': 0.3,
        'gridAlpha': 0,
      }];
    } else {
      axis = [{
        'stackType': 'regular',
        'axisAlpha': 0.3,
        'gridAlpha': 0,
        'totalText': '[[total]]'
      }];
    }
    const chat = [];
    for (const graphdata of this.data.widgets) {
      const data1 = {
        'balloonText': '[[title]]<span style=\'font-size:12px\'>: [[value]]</span>',
        'fillAlphas': 0.8,
        'labelPosition': 'middle',
        'labelRotation': 270,
        'lineAlpha': 0.3,
        'title': graphdata,
        'type': 'column',
        'color': '#000000',
        'valueField': graphdata,
        'fillColors': graphdata.color,
        'fixedColumnWidth': 50
      };
      chat.push(data1);
    }
    if (this.data.colorvalue === 'all') {
      this.chart = this.AmCharts.makeChart(this.id, {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'legend': {
          'position': 'top',
          'useGraphSettings': true,
          'markerSize': 10
        },
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': axis,
        'graphs': chat,
        'categoryField': this.data.title,
        'categoryAxis': {
          'gridPosition': 'start',
          'labelRotation': 90,
          'gridAlpha': 0.2,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'export':
        {
          'enabled': true,
          'processData': (data, cfg) => {
            if (cfg.extension === 'csv' && this.data.name === 'liveAgentChatGraph') {
              return this.data.csv;
            } else {
              return data;
            }
          },
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
        }
      });
    }
  }

  singleBarGraph() {
    let axis = {};
    if (this.largeTitleGraph.includes(this.data.name)) {
      axis = {
        'gridPosition': 'start',
        'labelRotation': 0,
        'maxLabelWidth': 100,
        'inside': false,
        'gridAlpha': 0.2,
        'tickPosition': 'start',
        'tickLength': 0.2,
        'autoWrap': true,
      };
    } else {
      axis = {
        'gridPosition': 'start',
        'labelRotation': 90,
        'inside': false,
        'gridAlpha': 0.2,
        'tickPosition': 'start',
        'tickLength': 0.2,
      };
    }
    this.chart = this.AmCharts.makeChart(this.id,
      {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': [{
          'stackType': 'regular',
          'axisAlpha': 0.3,
          'gridAlpha': 0,
          'dashLength': 0,
          'minumum': 0,
          'totalText': '[[total]]'
        }],
        'gridAboveGraphs': false,
        'startDuration': 1,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': this.data.value,
          'fillColors': ['#333333', '#8c8c8c'],
          'balloon': {
            'adjustBorderColor': true,
            'cornerRadius': 5,
            'maxWidth': 2000,
            'wrap': true
          }
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false,
        },
        'categoryField': this.data.title,
        'categoryAxis': axis,
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export': {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        },

        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grip

      });
  }

  templateBroadcastGraph() {
    this.templateBroadcastvar = [];
    const colorvalue = [];
    for (const graphdata of this.data.widgets) {
      const data1 = {
        'balloonText': '[[title]]<span style=\'font-size:12px\'>: [[value]]</span>',
        'fillAlphas': 0.8,
        'labelPosition': 'middle',
        'labelRotation': 270,
        'lineAlpha': 0.3,
        'title': graphdata.template_name,
        'type': 'column',
        'color': '#000000',
        'valueField': graphdata.template_name,
        'fillColors': graphdata.color,
        'fixedColumnWidth': 40
      };

      this.templateBroadcastvar.push(data1);
    }

    if (this.data.colorvalue === 'all') {
      this.chart = this.AmCharts.makeChart(this.id, {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'legend': {
          'position': 'top',
          'useGraphSettings': true,
          'markerSize': 10
        },
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': [{
          'stackType': 'regular',
          'axisAlpha': 0.3,
          'gridAlpha': 0,
          'totalText': '[[total]]'
        }],
        'graphs': this.templateBroadcastvar,
        'categoryField': 'date',
        'categoryAxis': {
          'gridPosition': 'start',
          'axisAlpha': 0.3,
          'gridAlpha': 0,
          'labelRotation': 90,
          'position': 'left',
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'export':
        {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        }
      });
    } else {
      this.chart = this.AmCharts.makeChart(this.id, {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'valueAxes': [{
          'gridAlpha': 0.2,
          'dashLength': 0
        }],
        'gridAboveGraphs': false,
        'startDuration': 1,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': 'count',
          'fillColors': colorvalue,
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false
        },
        'categoryField': '_id',
        'categoryAxis': {
          'gridPosition': 'start',
          'gridAlpha': 0.2,
          'labelRotation': 90,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export':
        {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        }
        ,
        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
      });
    }
  }

  agentFlowAnalysisGraph() {
    this.agentFlowVar = [];
    const colorvalue = [];
    const exportFields = this.data.exportsField;
    for (const graphdata of this.data.widgets) {

      const data1 = {
        'balloonText': '[[title]]<span style=\'font-size:12px\'>: [[value]]</span>',
        'fillAlphas': 0.8,
        'labelPosition': 'middle',
        'labelRotation': 270,
        'lineAlpha': 0.3,
        'title': graphdata['flowName'],
        'type': 'column',
        'color': '#000000',
        'valueField':  graphdata['flowName'],
        'fillColors': graphdata.color,
        'fixedColumnWidth': 40
      };
      this.agentFlowVar.push(data1);
    }
    if (this.data.colorvalue === 'all') {
      this.chart = this.AmCharts.makeChart(this.id, {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'legend': {
          'position': 'top',
          'useGraphSettings': true,
          'markerSize': 10
        },
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'titles': [
          {
            'text': this.data.header,
            'size': 15
          }
        ],
        'valueAxes': [{
          'stackType': 'regular',
          'axisAlpha': 0.3,
          'gridAlpha': 0,
          'totalText': '[[total]]'
        }],
        'graphs': this.agentFlowVar,
        'categoryField': 'Date',
        'categoryAxis': {
          'gridPosition': 'start',
          'axisAlpha': 0.3,
          'gridAlpha': 0,
          'labelRotation': 90,
          'position': 'left',
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'export': {
          'enabled': true,
          'processData': (data, cfg) => {
            let arr = [];
            if (cfg.extension === 'csv') {
              arr = data?.[0]?.['Total']?.map((item) => {
                const channel = item.channels;
                const agents = item.agents;
                const newObj = {
                  ['Date']: item?.date,
                  ['Flow Name']: item?.flow_name,
                  ['Total']: item?.count
                };
                channel.forEach((ch) => {
                  const channel = this.iconsService.applyChannelNameForDropDown(ch.connection_type);
                  if (!newObj[channel?.['name']]) {
                    newObj[channel?.['name']] = ch.count;
                  }
                });
                agents?.forEach((a) => {
                  if (a.engager_name) {
                    newObj[a.engager_name+'(Agent Name)'] = a.count;
                  }
                });
                return newObj;
              });
            }
            return arr;
          },
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF', 'CSV']
            }]
          }],
         'exportFields': exportFields,
          'pageOrigin': false,
        },
        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/',
      });
    } else {
      this.chart = this.AmCharts.makeChart(this.id, {
        'hideCredits': true,
        'type': 'serial',
        'theme': 'light',
        'lineColor': '#CC0000',
        'dataProvider': this.data?.graph ? this.data.graph : [],
        'valueAxes': [{
          'gridAlpha': 0.2,
          'dashLength': 0
        }],
        'gridAboveGraphs': false,
        'startDuration': 1,
        'graphs': [{
          'balloonText': '[[category]]: <b>[[value]]</b>',
          'fillAlphas': 1,
          'lineAlpha': 0,
          'type': 'column',
          'valueField': 'count',
          'fillColors': colorvalue,
        }],
        'chartCursor': {
          'categoryBalloonEnabled': false,
          'cursorAlpha': 0,
          'zoomable': false
        },
        'categoryField': '_id',
        'categoryAxis': {
          'gridPosition': 'start',
          'gridAlpha': 0.2,
          'labelRotation': 90,
          'tickPosition': 'start',
          'tickLength': 0.2
        },
        'chartScrollbar': {
          'updateOnReleaseOnly': true,
          'enabled': true,
        },
        'export':
        {
          'enabled': true,
          'fileName': this.data.fileName,
          'menu': [{
            'class': 'export-main',
            'menu': [{
              'label': 'Download',
              'menu': ['PNG', 'JPG', 'SVG', 'PDF' , 'CSV']
            }]
          }], // Shown menu when entering the annotation mode
          'pageOrigin': false,
        }
        ,
        'pathToImages': '/assets/amcharts_3.21.15.free/amcharts/images/', // required for grips
      });
    }
  }
}
