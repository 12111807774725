import { Component, Inject, OnInit } from '@angular/core';
import { LoaderService } from './loader/loader.service';
import { EnvironmentService } from './environment.service';
import { TranslateService } from '@ngx-translate/core';
import defaultLanguage from './../assets/i18n/en.json';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loader = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  constructor(
    private readonly loaderService: LoaderService,
    private readonly envServiceProvider: EnvironmentService,
    public translate: TranslateService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    translate.setTranslation('en', defaultLanguage);
    translate.setDefaultLang('en');
  }

    ngOnInit() {
        this.loaderService.status.subscribe((val: boolean) => {
            this.loader = val;
        });
        this.envServiceProvider.config({
          domains: {
            local: ['localhost'],
            stagingNest: ['staging-unifiedai.unificationengine.com'],
            unifiedAI: ['unified.ai'],
            development: ['dev.unified.ai'],
            snicUat: ['uat-chatbot.snic.com.bh'],
            snic: ['sniccb.snic.com.bh'],
          },
          vars: {
            local: {
              appName: 'unifiedai',
              vCardFile: 'unifiedai.vcf',
               apiUrl: 'https://staging-unifiedai-api.unificationengine.com/console',
               socketUrl: 'https://staging-unifiedai-api.unificationengine.com',
              // apiUrl: 'http://localhost:7444',
              // socketUrl: 'http://localhost:7444',
              apiUrlCount: 7,
              Authorization: 'aZ2ZmJ6wKfKtgcvD',
              statusKey: 'C7646E98438F7FC2AD6C2717DFE9D',
              smartContactImage: [
                { url: 'uiblogo-smart.png', height: ' 50px', margin: '30px' },
              ],
              clientId: '221460181203789',
              redirectUrl: 'https://cb13-202-88-246-112.ngrok.io/callback/fb/auth',
              ssoEnabled: true
            },
            stagingNest: {
              appName: 'unifiedai',
              vCardFile: 'unifiedai.vcf',
              // apiUrl: 'https://staging-unifiedai-api.unificationengine.com',
              apiUrl: '/console',
              socketUrl: '',
              Authorization: 'aZ2ZmJ6wKfKtgcvD',
              statusKey: 'C7646E98438F7FC2AD6C2717DFE9D',
              smartContactImage: [
                { url: 'uiblogo-smart.png', height: '50px', margin: '30px' },
              ],
              clientId: '221460181203789',
              redirectUrl: 'https://staging-unifiedai-api.unificationengine.com/callback/fb/auth',
              ssoEnabled: true
            },
            development: {
              appName: 'unifiedai',
              vCardFile: 'unifiedai.vcf',
              apiUrl: 'https://development-api.unified.ai',
              socketUrl: 'https://development-api.unified.ai',
              Authorization: 'aZ2ZmJ6wKfKtgcvD',
              statusKey: 'C7646E98438F7FC2AD6C2717DFE9D',
              smartContactImage: [
                { url: 'uiblogo-smart.png', height: '50px', margin: '30px' },
              ],
              clientId: '221460181203789',
              redirectUrl: 'https://development-api.unified.ai/callback/fb/auth',
              ssoEnabled: true
            },
            unifiedAI: {
              appName: 'unifiedai',
              vCardFile: 'unifiedai.vcf',
              apiUrl: 'https://api.unified.ai',
              socketUrl: '',
              Authorization: 'aZ2ZmJ6wKfKtgcvD',
              statusKey: 'C7646E98438F7FC2AD6C2717DFE9D',
              smartContactImage: [
                { url: 'uiblogo-smart.png', height: '50px', margin: '30px' },
              ],
              clientId: '221460181203789',
              redirectUrl: 'https://api.unified.ai/callback/fb/auth',
              ssoEnabled: true
            },
            snicUat: {
              appName: 'unifiedai',
              vCardFile: 'unifiedai.vcf',
              apiUrl: 'https://api-uat-chatbot.snic.com.bh',
              socketUrl: 'https://api-uat-chatbot.snic.com.bh',
              Authorization: 'aZ2ZmJ6wKfKtgcvD',
              statusKey: 'C7646E98438F7FC2AD6C2717DFE9D',
              smartContactImage: [
                { url: 'uiblogo-smart.png', height: '50px', margin: '30px' },
              ],
              clientId: '221460181203789',
              redirectUrl: 'https://api-uat-chatbot.snic.com.bh/callback/fb/auth',
              ssoEnabled: false
            },
            snic: {
              appName: 'unifiedai',
              vCardFile: 'unifiedai.vcf',
              apiUrl: 'https://sniccb-api.snic.com.bh', // NOSONAR
              socketUrl: 'https://sniccb-api.snic.com.bh', // NOSONAR
              Authorization: 'IM5NnLMSyVrQJREJ',
              statusKey: 'C7646E98438F7FC2AD6C2717DFE9D',
              smartContactImage: [
                { url: 'uiblogo-smart.png', height: '50px', margin: '30px' },
              ],
              clientId: '221460181203789',
              redirectUrl: 'https://sniccb-api.snic.com.bh/callback/fb/auth',
              ssoEnabled: false
            },
          },
        });
        this.envServiceProvider.check();
    }
    loadStyle(styleName: string) {
      const head = this.document.getElementsByTagName('head')[0];
      const themeLink = this.document.getElementById(
        'client-theme'
      ) as HTMLLinkElement;
      if (themeLink) {
        themeLink.href = styleName;
      } else {
        const style = this.document.createElement('link');
        style.id = 'client-theme';
        style.rel = 'stylesheet';
        style.href = `${styleName}`;
        head.appendChild(style);
      }
    }
  }

