import {Component, Inject, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import cytoscape from 'cytoscape';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { FlowBuilderService } from '../../flow-builder/flow-builder.service';
import { ActivatedRoute } from '@angular/router';
import popper from 'cytoscape-popper';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { stylesheetPreviewObject } from './stylesheetPreviewObject';
import cytoscapeDagre from 'cytoscape-dagre';
// @ts-ignore
import cytoscapeNodeHtmlLabel from 'cytoscape-node-html-label';
import { ToastrService } from 'ngx-toastr';

cytoscape.use(popper);
cytoscape.use(cytoscapeNodeHtmlLabel);
cytoscape.use(cytoscapeDagre);

@Component({
  selector: 'app-flowbuilder-preview',
  templateUrl: './flowbuilder-preview.component.html',
  styleUrls: ['./flowbuilder-preview.component.scss']
})
export class FlowbuilderPreviewComponent implements OnInit, AfterViewInit {
  constructor(public flowBuilderService: FlowBuilderService, public route: ActivatedRoute,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<FlowbuilderPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,  private readonly toastr: ToastrService) {
    this.graph = data.graph;
    this.lang =  data.language;
    this.languageUpperCase = this.lang?.toUpperCase();
    this.flowName = data.flowName;
    this.linepattern = data.linepattern;
    this.linetype = data.linetype;
    this.flowbuilderId = data.flowbuilderId;
  }

  toDate: any = moment();
  fromDate: any = moment().subtract(30, 'days');
  viewdropdown = false;
  radiovalue: any;
  valueforUpdate = [];
  searchQuery: string;
  lineTypeItem: any;
  flowbuilderId: any;
  flowName: any;
  getid: any;
  withCount;
  linetype: any;
  linepattern: any;
  lang: any;
  count = 0;
  Query: any;
  foundNode: any = [];
  flowNodeUsageSave = [];
  numberOfNodes = false;
  bgNodeColor = false;
  themeNode: string;
  languageUpperCase: any;
  screenWidth = window.screen.width;
  screenHeight = window.screen.height;
  theme: string;
  Type = 'withcount';
  model3: any = {
    power: 'All'
  };
  themeStyles = {
    'orange-style.css': { backgroundColor: '#FFD078', rootColor: '#e6a03f', themeNode: 'orange' },
    'green-style.css': { backgroundColor: '#CCFBCC', rootColor: 'green', themeNode: 'green' },
     'light-green-style.css': { backgroundColor: '#CCFFF5', rootColor: '#3bbd9b', themeNode: 'light-green' },
     'blue-style.css': { backgroundColor: '#DBEDFF', rootColor: '#57a6f4', themeNode: 'blue' },
     'black-style.css': { backgroundColor: '#BEBEBE', rootColor: '#3B3B3B', themeNode: 'black' },
    };
    nodeTypeImages = {
      'slot_question': 'slot-question.svg',
      'menu_question': 'menu-response.svg',
       'menu_item': 'menu-item.svg',
       'conditional_response': 'conditional-response.svg',
       'condition': 'condition.svg',
       'end_response': 'end-response.svg',
    };
  public cy: any;
  public graph: any;
  public showAllStyle: any = stylesheetPreviewObject;
  public eh: any;
  public overview: any;
  private options: any;
  uploadloader = false;
  userQuestionUpdate = new Subject<string>();
  @ViewChild('cyContainer', { static: false }) cyContainer: ElementRef;

  ngOnInit() {
    this.withCount = true;
    this.theme = localStorage.getItem('theme');
    this.userQuestionUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.count = 0;
        this.searchNode(value);
      });
     window.addEventListener('resize', this.resizeCytoscapeContainer.bind(this));
  }
  ngOnDestroy(): void {
    const elements = document.querySelectorAll('.popper-div');
    if (elements !== null) {
      elements.forEach((element) => {
          element.remove();
      });
    }

    window.removeEventListener('resize', this.resizeCytoscapeContainer.bind(this));
  }
  handleDateRangeChange(event: Date[]) {
   // this.getdate()
  }
  ngAfterViewInit() {
    cytoscape.use(cytoscapeNodeHtmlLabel);
    this.cy = cytoscape({
      container: this.cyContainer?.nativeElement,
      elements: this.graph,
      style: this.showAllStyle,
      layout: {
        name: 'preset',
      },
      zoomingEnabled: false,
    });

    this.updateNodeBackground();


  this.cy?.on('mouseover', 'node', (event) => {
      const node = event.target;
      if (node.data().label && node.data().label !== '') {
        if (!node.popperRefObj) {
          node.popperRefObj = node.popper({
            content: () => {
              const content = document.createElement('div');
              content.classList.add('popper-div');
              content.innerHTML = node.data().label;
              content.style.zIndex = '9999';
              document.body.appendChild(content);
              return content;
            },
            popper: { placement: 'top' }
          });
        }
      }
    });


    this.cy?.on('mouseout', 'node', (event) => {

      const node = event.target;
        if (event?.target?.popperRefObj && event?.target?.popper) {
             event.target.popperRefObj.state.elements.popper.remove();
            event.target.popperRefObj.destroy();
            node.popperRefObj = null;
        }
    });

    this.options =  {
      name: 'preset',
      positions: undefined,
      grid: true,
      zoom: 0.8,
      pan: false,
      fit: true,
      padding: 0,
      avoidOverlap: true,
      animate: false,
      animationDuration: 500,
      animationEasing: undefined,
      animateFilter: function (node, i) { return true; },
      ready: undefined,
      stop: undefined,
      transform: function (node, position) {
        return position;
      }
    };
    const layout = this.cy.layout(this.options);
    layout.run();
    this.cy.nodes().forEach(node => {
      node.lock();
    });

    this.cy.minZoom(0.2);
    this.cy.maxZoom(1);
    this.cy?.on('render', (event) => {

      this.cy.nodes('.has-images').forEach((node) => {

        this.createOrLabelverlay(node, this.Type);
      });
    });

    this.cy.style()
    .selector('edge')
    .style({
      'curve-style': this.linetype?.value,
      'line-style': this.linepattern?.value
    }).update();
    this.resizeCytoscapeContainer();
    this.resetZoom();
  }

  createOrLabelverlay(node, type) {

    this.cy?.zoomingEnabled(false);
    const overlayId = `overlay-count-${node.id()}`;
    let overlay = document.getElementById(overlayId);
    const renderedPosition = node.renderedPosition();
    const width = node.renderedWidth();

    if (!overlay) {

      overlay = document.createElement('div');
      overlay.id = overlayId;
      const labeldiv = document.createElement('div');
      overlay.appendChild(labeldiv);
      labeldiv.style.position = 'relative';


    }

    if (this.cy.zoom() < 0.55 && this.cy.zoom() > 0.46) {
      overlay.style.left =  `${renderedPosition.x + (width - 40) / 2}px`;
      overlay.style.top = `${(renderedPosition.y - 18)}px`;
      overlay.style.transform = `scale(${this.cy.zoom()})`;
    } else if (this.cy.zoom() < 0.45) {
      overlay.style.left =  `${renderedPosition.x + (width - 30) / 2}px`;
      overlay.style.top = `${(renderedPosition.y - 16)}px`;
      overlay.style.transform = `scale(${this.cy.zoom()})`;
    } else {
      overlay.style.left =  `${renderedPosition.x + (width - 50) / 2}px`;
      overlay.style.top = `${(renderedPosition.y - 20)}px`;
      overlay.style.transform = `scale(${this.cy.zoom()})`;
    }
  }
  onCancel(): void {

    this.dialogRef.close();
    if (this.Type === 'withoutcount') {
      this.graph.nodes.forEach((node) => { // Use forEach for direct iteration
        const savedNode = this.flowNodeUsageSave.find((saved) => saved.id === node.data.id);
        const nodeId = this.cy.getElementById(node.data.id); // Make sure to use node.data.id
        if (nodeId.length > 0 && savedNode) {
          nodeId.data('flowNodeUsageCount', savedNode.flowNodeUsageCount); // Reset the data using saved object
        }
      });
      this.dialogRef.close();

    }

  }
  onRadioChange(item) {
    this.radiovalue = item;

    if (item === 'withoutcount') {
      this.Type = 'withoutcount';
      this.withCount = false;

      this.flowNodeUsageSave = []; // Make sure this is initialized as an empty array first

      for (const node of this.graph.nodes) {
          // Create an object with both id and flowNodeUsageCount and push it into the array
          this.flowNodeUsageSave.push({
              id: node.data.id,
              flowNodeUsageCount: node.data.flowNodeUsageCount
          });

          const nodeId = this.cy.getElementById(node.data.id);
          if (nodeId.length > 0) {
              nodeId.data('flowNodeUsageCount', ''); // Set the data to an empty string
          }
      }
  } else {
    this.Type = 'withcount';
    this.withCount = true;
    this.changedatepicker();
    this.graph.nodes.forEach((node) => { // Use forEach for direct iteration
      const savedNode = this.flowNodeUsageSave.find((saved) => saved.id === node.data.id);
      const nodeId = this.cy.getElementById(node.data.id); // Make sure to use node.data.id
      if (nodeId.length > 0 && savedNode) {
        nodeId.data('flowNodeUsageCount', savedNode.flowNodeUsageCount); // Reset the data using saved object
      }
    });
  }

}

  updateNodeBackground() {
    this.bgNodeColor = false;
    const currentThemeStyle = this.themeStyles[this.theme] || {};
    this.themeNode = currentThemeStyle.themeNode;
    this.cy.nodes().forEach((node) => {
      const nodeElement = this.cy.getElementById(node.data().id);
      const isRootNode = node.data().node_type === 'root';
      const isEndNode = node.data().node_type === 'end_response';
      const bgColor = this.setBackgroundColor(isEndNode, isRootNode, currentThemeStyle);
      if (bgColor) {
        nodeElement.style('background-color', bgColor);
        nodeElement.style('color', currentThemeStyle.rootColor);
        this.bgNodeColor = true;
      }
      const nodeImage = this.nodeTypeImages[node.data().node_type];
      if (nodeImage && this.themeNode) {
        const imageUrl = `../../assets/images/flowbuilder-icon/${this.themeNode}/${nodeImage}`;
        nodeElement.style('background-image', `url("${imageUrl}")`);
      }
      if (isRootNode) {
        nodeElement.style('color', 'white');
      }
      if (isEndNode) {
        nodeElement.style('color', 'red');
      }
    });

  }
  setBackgroundColor(isEndNode, isRootNode, currentThemeStyle) {
    let bgColor;
    if (isEndNode) {
        bgColor = '#FF9A9A';
    } else if (isRootNode) {
        bgColor = currentThemeStyle.rootColor;
    } else {
        bgColor = currentThemeStyle.backgroundColor;
    }
    return bgColor;
  }
  handleDeletion() {
    this.cy?.nodes().forEach(node => {
      const nodeId = node.id();
      const overlay = document.getElementById(`overlay-count-${nodeId}`);
      if (overlay) {
        overlay.parentNode.removeChild(overlay);
      }
    });
  }
zoomIn(): void {
  this.cy?.zoomingEnabled(true);
  this.cy?.zoom({
    level: this.cy.zoom() * 1.2,
    renderedPosition: { x: this.cy.width() / 2, y: this.cy.height() / 2 }
  });
}

resetZoom(): void {
  this.cy?.zoomingEnabled(true);
  this.cy?.zoom({
    level: 0.8,
    renderedPosition: { x: this.cy.width() / 2, y: this.cy.height() / 2 }
  });
  this.foundNode = this.cy?.nodes()?.filter(node => node.data('node_type')?.toLowerCase().includes('root'.toLowerCase()));
  if (this.foundNode?.nonempty()) {
     this.cy?.animate({
       center: { eles: this.foundNode[0]  },
     }, {
       duration: 500
     });
 }
}

zoomOut(): void {
  this.cy?.zoomingEnabled(true);
  this.cy?.zoom({
    level: this.cy.zoom() / 1.1,
    renderedPosition: { x: this.cy.width() / 2, y: this.cy.height() / 2 }
  });
  }
searchNode(query: string) {
  this.cy?.nodes().forEach((node) => {
    node.style({
      'text-background-color': '',
      'text-background-opacity': 0,
      'text-background-padding': 0,
      'text-background-shape': ''
    });
  });
 this.Query = query;
 this.foundNode = this.cy.nodes().filter(node => node.data('dummyLabel').toLowerCase().includes(query.toLowerCase()));
 if (this.foundNode.length > 0) {
      if (query.length >= 1) {
    this.foundNode.forEach(node => {
      node.style({
        'text-background-color': 'yellow',
      'text-background-opacity': 1,
      'text-background-padding': 4,
      'text-background-shape': 'roundrectangle'
      });
    });
    this.numberOfNodes = true;
    this.cy.animate({
      center: { eles: this.foundNode[0]  },
      zoom: 1
    }, {
      duration: 500
    });
  } else {
    this.numberOfNodes = false;


  }
}


}
searchup() {
  if (this.foundNode.length === 0) {
    return;
  }
  this.count = this.count + 1;
  if (this.count > this.foundNode.length - 1) {
    this.count = 0;
    }

   if (this.foundNode.length > 0)  {
    if (this.Query?.length >= 1) {

    this.cy.animate({
      center: { eles: this.foundNode[this.count]  },
      zoom: 1
    }, {
      duration: 500
    });
  }
}
}
searchdown() {
  if (this.foundNode.length === 0) {
    return;
  }
  this.count = this.count - 1;
  if (this.count < 0) {
  this.count = this.foundNode.length - 1;
  }
  if (this.foundNode.length > 0)  {
    if (this.Query.length >= 1 ) {
    this.cy.animate({
      center: { eles: this.foundNode[this.count]  },
      zoom: 1
    }, {
      duration: 500
    });
  }
}
}
downloadJPG() {
  const cy = this.cy;
  const padding = 40;
  cy.jpg({ full: true, output: 'blob-promise' }).then((imgBlob) => {
    const originalImage = new Image();
    originalImage.src = URL.createObjectURL(imgBlob);
    originalImage.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = originalImage.width + (2 * padding);
      canvas.height = originalImage.height + (2 * padding);
      const context = canvas.getContext('2d');
      context.fillStyle = '#ffffff';
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(originalImage, padding, padding);
      canvas.toBlob((blob) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = this.flowName + '.jpg';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }, 'image/jpeg');
    };
  });
}

downloadSVG() {
  const cy = this.cy;
  const svgContent = cy.svg({ scale: 1, full: true });
  const blob = new Blob([svgContent], { type: 'image/svg+xml' });
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = this.flowName + '.svg';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
downloadCSV() {
  const encapsulateInQuotes = (val: any) => {
    let value = val || '';
    if (typeof value === 'string') {
      value = value.replace(/"/g, '""');
      return `"${value}"`;
    }
    return value;
  };
  let csvContent = 'data:text/csv;charset=utf-8,';

  csvContent += 'ID, Node Type, Label, Type, Previous Message, Node Name, Next Message, Condition Check, Condition ID, Condition Operator, Condition Value, Condition Check Node Name, Group ID,Position\n';


  this.cy.nodes().forEach(node => {
    if (node.data('node_type') && node.data('node_type') !== null && node.data('node_type') !== '') {
      csvContent += `${node.id()},${encapsulateInQuotes(node.data('node_type'))},${encapsulateInQuotes(node.data('label'))},${encapsulateInQuotes(node.data('type'))},${encapsulateInQuotes(JSON.stringify(node.data('prev_message')))},${encapsulateInQuotes(node.data('node_name'))},${encapsulateInQuotes(JSON.stringify(node.data('next_message')))},${encapsulateInQuotes(node.data('condition_check'))},${encapsulateInQuotes(node.data('condition_id'))},${encapsulateInQuotes(node.data('condition_operator'))},${encapsulateInQuotes(node.data('condition_value'))},${encapsulateInQuotes(node.data('conditionCheckNodeName'))},${encapsulateInQuotes(node.data('groupId'))},${encapsulateInQuotes(JSON.stringify(node.position()))}\n`;
    }

  });

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', this.flowName + '.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
downloadImage() {
  const cy = this.cy;
  const imgBlob = cy.png({ full: true, output: 'blob' });

  const a = document.createElement('a');
  a.href = URL.createObjectURL(imgBlob);
  a.download = this.flowName + '.png';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}


navigateLeft() {
  const currentPan = this.cy.pan();
  this.cy.pan({
    x: currentPan.x + 500,
    y: currentPan.y
  });
}
navigateRight() {
  const currentPan = this.cy.pan();
  this.cy.pan({
    x: currentPan.x - 500,
    y: currentPan.y
  });
}
navigateUp() {
  const currentPan = this.cy.pan();
  this.cy.pan({
    x: currentPan.x ,
    y: currentPan.y + 300
  });
}
navigateDown() {
    const currentPan = this.cy.pan();
    this.cy.pan({
      x: currentPan.x ,
      y: currentPan.y - 300
    });

}
resizeCytoscapeContainer() {
  const newWidth = window.innerWidth; // Or some logic to calculate based on dialog size
  const newHeight = window.innerHeight; // Or some logic to calculate based on dialog size
  this.cyContainer.nativeElement.style.width = `${newWidth}px`;
  this.cyContainer.nativeElement.style.height = `${newHeight}px`;
  this.cy.resize();
  this.cy.fit();
}
changedatepicker() {
  this.uploadloader = true;
  if (this.model3.power === 'last30') {
    this.viewdropdown = false;
    const r = new Date();
    const d = new Date();
    // tslint:disable-next-line:radix
    d.setDate(d.getDate() - parseInt('30'));
    this.fromDate = d;
    this.toDate = r;
    const datevaluesArray = [];
    datevaluesArray.push(this.fromDate, this.toDate);
    this.getdate(datevaluesArray);
  } else if (this.model3.power === 'last7') {
    this.viewdropdown = false;
    const r = new Date();
    const d = new Date();
    // tslint:disable-next-line:radix
    d.setDate(d.getDate() - parseInt('7'));
    this.fromDate = d;
    this.toDate = r;
    const datevaluesArray = [];
    datevaluesArray.push(this.fromDate, this.toDate);
    this.getdate(datevaluesArray);
  } else if (this.model3.power === 'choosedate' ) {
    this.toDate = moment();
    this.fromDate = moment().subtract(30, 'days');
    this.viewdropdown = true;

  } else if (this.model3.power === 'All') {
    const d = new Date();
    this.viewdropdown = false;
    this.fromDate = '1546325132000';
    this.toDate =  d.setDate(d.getDate());

    this.flowBuilderService.flowBuilderData(this.flowbuilderId, this.model3.power, this.fromDate, this.toDate).subscribe({
      next: (data) => {
       this.updateFlowElements(data.flows);
      }});

  } else {
    this.viewdropdown = true;
  }
}
getdate(dateArray) {
  this.fromDate = dateArray.length > 0 && dateArray[0].getTime() ? dateArray[0].getTime() : this.toDate.getTime();
  this.toDate = dateArray.length > 0 && dateArray[1].getTime() ? dateArray[1].getTime() : this.fromDate.getTime();
this.flowBuilderService.flowBuilderData(this.flowbuilderId, this.model3.power, this.fromDate, this.toDate).subscribe({
  next: (data) => {
   this.updateFlowElements(data.flows);
  }});
}
dateChanges(dateArray) {
  if (dateArray && typeof dateArray[1] !== 'number') {
    const to = dateArray[1]?.getTime();

    const today = new Date().getTime();
    if (to <= today) {
      this.getdate(dateArray);
      } else {
        this.toastr.error(this.translate.instant('toastr.select_valid_date_range'));
      }
  }
}
updateFlowElements(flows: any) {
  flows.forEach((data: any) => {
    const nodeId1 = this.cy?.getElementById(data.id);
    if (nodeId1.length > 0) {
      nodeId1.data('id', data.id);
      nodeId1.data('node_type', data.node_type);
      nodeId1.data('node_name', data.node_name);
      nodeId1.data('label', data.label);
      nodeId1.data('dummyLabel', this.getLabelchanges(data.label));
      nodeId1.data('flowNodeUsageCount', data.flowNodeUsageCount);
      nodeId1.data('image_details', data.image_details);
      nodeId1.data('condition_check', data.condition_check);
      nodeId1.data('groupId', data.groupId);
      nodeId1.data('submit_action', data.submit_action);
      nodeId1.data('next_message', data.next_message);
      nodeId1.data('prev_message', data.prev_message);
      nodeId1.data('variableArray', data.variableArray);
      nodeId1.data('user_attribute', data.userAttribute);
      nodeId1.data('sortNumber', data.sortNumber);
      nodeId1.data('condition_id', data.condition_id);
      nodeId1.data('condition_operator', data.condition_operator);
      nodeId1.data('condition_value', data.condition_value);
      nodeId1.data('type', data.type);
      nodeId1.data('flow_id', data.flow_id);
      nodeId1.data('conditionCheckNodeName', data.conditionCheckNodeName);
      this.cy.getElementById(data.id).addClass('has-images');
    }
  });

  this.updateNodeBackground();
  this.cy.nodes('.has-images').forEach((node) => {
    this.createOrLabelverlay(node, this.Type);
  });
  this.uploadloader = false;
}
getLabelchanges(label: any) {
  if (!label) {
    return '';
  } else {

    const labelMatch = label.replace(/<[^>]*>/g, ' '); // NOSONAR
    return labelMatch || label;

  }
}
onKeyDown(event: KeyboardEvent): void {
  if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      document.getElementById('dropdownMenuLink')?.click();
  }
}

}
