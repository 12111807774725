import { Component, ElementRef, inject, Injector, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LayoutAreas } from './layout-areas';
import { FlowBuilderService } from '../flow-builder/flow-builder.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

type LayoutId = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
interface LayoutAreaData {
  areaId: number;
  text?: string;
  isUrl?: boolean;
  url?: string;
  styles: { [key: string]: string | number };
}

@Component({
  selector: 'app-image-layout',
  templateUrl: './image-layout.component.html',
  styleUrls: ['./image-layout.component.scss']
})
export class ImageLayoutComponent {
  dialogData: {} = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);
  selectedRatio: '1' | '2' = '1';
  selectedLayoutId: LayoutId = 1;
  layoutAreaOptions: Array<LayoutAreaData> = LayoutAreas()[this.selectedLayoutId];
  selectedLayoutArea: LayoutAreaData | null;
  @ViewChild('imageContainer') imageContainer!: ElementRef;
  imageDimensions: { width: number; height: number; };
  @ViewChild('imageElement') imageElement!: ElementRef<HTMLImageElement>;
  selectedArea: { width: number; height: number; };
  selectedPosition: { x: number; y: number; };
  vewImageSize: { width: number; height: number; };
  flowBuilderService: FlowBuilderService;
  uploadloader: boolean;
  fileType: any;
  uploadloaderImage: boolean;
  @ViewChild('fileInput') fileInput: ElementRef;
  setVideo = false;
  translatorLanguage: any;
  disableImagemap = false;
  constructor(private readonly injector: Injector, private readonly toastr: ToastrService, private readonly translate: TranslateService) {
    this.flowBuilderService = this.injector.get<FlowBuilderService>(FlowBuilderService);
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
    this.flowBuilderService.data$.subscribe(data => {
      if (data && data.type === 'response' && data.file?.url) {
        this.handleUpladFile(data);
      } else {
      this.uploadloader = false;
      this.uploadloaderImage = false;
      }
    });
    if(this.dialogData['lineImageMap']) {
      const layoutId = this.dialogData['layoutId'];
      this.selectedLayoutId = this.dialogData['layoutId'];
     const layoutAreaOptions = this.dialogData['layoutAreaOptions'];
     if(layoutAreaOptions) {
      this.layoutAreaOptions = JSON.parse(layoutAreaOptions);
      this.layoutAreaOptions.forEach(item=> {
        if(item.text || item['videoUrl'] || item['linkUri']) {
          item['isValidate'] = true;
        }
      })
    }
    }
    this.disableImagemap = false;
  }
  onLayoutSelect(layoutId: LayoutId) {
    this.selectedLayoutId = layoutId;
    this.layoutAreaOptions = LayoutAreas()[layoutId];
    this.selectedLayoutArea = null;
  }

  onActionSelect(e: string, index) {
    this.selectedLayoutArea['action'] = e;
    this.selectedLayoutArea.isUrl = (e === 'uri');
    console.log("this.selectedLayoutArea.isUrl",this.selectedLayoutArea.isUrl, e)
    if (!this.selectedLayoutArea.isUrl) {
      this.selectedLayoutArea.url = '';
    } else {
      this.selectedLayoutArea.text = '';
    }
  }
  selectLayoutArea(area: any, layoutSection: HTMLElement, imageDiv: HTMLElement, event: MouseEvent) {
    this.selectedLayoutArea = area;
    if (!this.selectedLayoutArea['action']) {
      this.selectedLayoutArea['action'] = 'message';
    }
    // Get the position of the selected layout area relative to the viewport
    const layoutRect = layoutSection.getBoundingClientRect();
    const imageLayout = imageDiv.getBoundingClientRect();
    // Get the position of the image container relative to the viewport
    const containerRect = this.imageContainer.nativeElement.getBoundingClientRect();
    // Calculate the X and Y coordinates relative to the image container
    const x = layoutRect.left - containerRect.left;
    const y = layoutRect.top - containerRect.top;
    const width = layoutRect.width;
    const height = layoutRect.height;
    const widthImg = imageLayout.width;
    const heightImg = imageLayout.height;
    this.selectedArea = {
      width: Math.round(width),
      height: Math.round(height)
    }
    this.selectedPosition = {
      x: Math.round(x),
      y: Math.round(y)
    }
    this.vewImageSize = {
      width: Math.round(widthImg),
      height: Math.round(heightImg)
    }
    const videoItem = this.layoutAreaOptions?.some(item => item['action'] === 'video' && item['isValidate']);
    if (videoItem) {
      this.setVideo = true;
    }
  }
  getfilenameFromURL(url) {
    const match = url?.match(/\/(\d+)$/);
    return match ? match[1] : null;
  }
  onImageLoad(event: Event): void {
    const image = event.target as HTMLImageElement;
    // Get image natural width and height (the actual dimensions of the image)
    this.imageDimensions = {
      width: Math.round(image.naturalWidth),
      height: Math.round(image.naturalHeight),
    };
    if(this.imageDimensions.width !== 1040) {
      this.disableImagemap = true;
      this.toastr.error('', this.translate.instant('toastr.lineImageSizeError'));
      // this.closeDialog()
    }
  }
  imagePositionCalulate() {
    const selectedArea = {
      x: this.pixalRatio(this.selectedPosition?.x, this.vewImageSize?.width, this.imageDimensions?.width),
      y: this.pixalRatio(this.selectedPosition?.y, this.vewImageSize?.height, this.imageDimensions?.height),
      width: this.pixalRatio(this.selectedArea?.width, this.vewImageSize?.width, this.imageDimensions?.width),
      height: this.pixalRatio(this.selectedArea?.height, this.vewImageSize?.height, this.imageDimensions?.height)
    }
    return selectedArea;
  }
  pixalRatio(position, viewSize, imgSize) {
    return Math.floor((position / viewSize) * imgSize);
  }
  handleFileUpload(data: any, type) {
    const Editfiles = data.target.files;
    const file = Editfiles[0];
    this.fileType = type;
    const pattern1 = /.*jpeg.*/; // NOSONAR
    const pattern2 = /video-*/;
    const pattern3 = /.*png.*/; // NOSONAR
    if (this.fileType === 'video') {
      this.uploadloader = true;
      if(!file.type.match(pattern2)) {
        this.toastr.error('', this.translate.instant('toastr.invalid_format'));
        this.setfileInput();
        this.uploadloader = false;
        return;
      }
    } else {
      this.uploadloaderImage = true;
      if(!file.type.match(pattern1) && !file.type.match(pattern3)) {
        this.toastr.error('', this.translate.instant('toastr.invalid_format'));
        this.setfileInput();
        this.uploadloaderImage = false;
        return;
      }
    }
    this.flowBuilderService.updateData({ file: data, type: 'upload' });
  }
  handleUpladFile(data) {
    this.uploadloader = false;
    this.uploadloaderImage = false;
    this.setfileInput();
    if (this.fileType === 'video') {
      this.selectedLayoutArea['videoUrl'] = data.file?.url;
      this.selectedLayoutArea['videoUrlOrginal'] = data.file?.url_orginal;
      this.selectedLayoutArea['filename'] = data.file?.filename;
    }
    if (this.fileType === 'image') {
      this.selectedLayoutArea['previewImage'] = data.file?.url;
      this.selectedLayoutArea['previewImageOrginal'] = data.file?.url_orginal;
      this.selectedLayoutArea['previewImageName'] = data.file?.filename;
    }
    this.selectedLayoutArea.text = '';
  }
  imageCrossClick(type) {
    if (type === 'video') {
      this.selectedLayoutArea['videoUrl'] = '';
      this.selectedLayoutArea['filename'] = '';
    } else {
      this.selectedLayoutArea['previewImage'] = '';
      this.selectedLayoutArea['previewImageName'] = '';
    }
    this.selectedLayoutArea['isValidate'] = false;
  }
  updateLayoutData() {
    this.selectedLayoutArea['area'] = this.imagePositionCalulate();
    let valid = true;
    if (this.selectedLayoutArea['action'] === 'video') {
      if (!this.selectedLayoutArea['videoUrl']) {
        valid = false;
      } else if (!this.selectedLayoutArea['previewImage']) {
        valid = false;
      }
    }
    if (this.selectedLayoutArea['action'] === 'message') {
      if (!this.selectedLayoutArea['text']) {
        valid = false;
      }
    }
    if (this.selectedLayoutArea['action'] === 'uri') {
      if (!this.selectedLayoutArea['linkUri']) {
        valid = false;
      }
    }
    if (valid) {
      this.selectedLayoutArea['isValidate'] = true;
    } else {
      this.selectedLayoutArea['isValidate'] = false;
    }
    this.selectedLayoutArea = null;
  }
  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
    this.selectedLayoutArea = null;
  }
  updateImageMap() {
    const isvalid = this.layoutAreaOptions?.some(item => item['isValidate']);
    if (!isvalid) {
      this.dialogRef.close({ event: 'close'});
      return;
    }
    let lineMessage = {
      type: 'imagemap',
      altText: 'Imagemap',
      baseUrl: this.dialogData?.['image_url_org']+'?width='+this.imageDimensions?.width,
      baseSize: this.imageDimensions,
    };
    let video;
    let actions = [];
    this.layoutAreaOptions.forEach(item => {
      if (item['isValidate']) {
        if (item['action'] === 'video' && item['videoUrl']) {
          video = {
            originalContentUrl: item['videoUrlOrginal'],
            previewImageUrl: item['previewImageOrginal'],
            area: item['area'],
          };
          lineMessage['video'] = video;
        } else {
          const action = {
            type: item['action'],
            area: item['area'],
          };
          if (item['action'] === 'uri') {
            action['linkUri'] = item['linkUri']
          }
          if (item['action'] === 'message') {
            action['text'] = item['text']
          }
          actions?.push(action);
        }
      }
    })
    lineMessage['actions'] = actions;
    this.dialogRef.close({ event: 'close', data: lineMessage, layoutId: this.selectedLayoutId , layoutAreaOptions: JSON.stringify(this.layoutAreaOptions)});
  }
  setfileInput() {
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }
  clearField() {
    const index = this.layoutAreaOptions.findIndex(item => item.areaId === this.selectedLayoutArea?.areaId);
    if(index > -1) {
      const layout = LayoutAreas()[this.selectedLayoutId];
      this.layoutAreaOptions[index] = layout[index]
    }
    this.selectedLayoutArea = null;
  }
  setInvalid() {
    this.selectedLayoutArea['isValidate'] = false;
  }
}
