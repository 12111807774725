import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SmartContactService {
  apiUrl: string;
  httpOptions: any;
  httpOptions1: any;
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
    private readonly sharedService: SharedsService,
    private readonly expService: ExceptionHandlerService
  ) {
    this.apiUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic YVoyWm1KNndLZkt0Z2N2RDo=',
        'access-token': this.sharedService.token,
      }),
    };
    this.httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.sharedService.token,
      }),
    };
  }

  image_upload(data): Observable<any> {
    this.httpOptions.params = null;
    const args = {
      image: data.image,
      uuid: data.uuid,
      appId: this.sharedService.appId,
      timeZone: this.sharedService.timeZone
    };

    return this.http
      .post<any>(
        `${this.apiUrl}/v1/smartcontact/update`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  update(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http.post<any>(`${this.apiUrl}/v1/smartcontact/update`, args, this.httpOptions)
    .pipe(
      tap(),
      catchError((error) => {
        this.expService.handleError(error);
        return of(error);
      })
    );
  }

  // tslint:disable-next-line:indent
	getContact(): Observable<any> {
    const args = { appId: this.sharedService.appId ,
      timeZone: this.sharedService.timeZone};
    return this.http
      .post<any>(`${this.apiUrl}/v1/contact/list`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  generateVcard(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(`${this.apiUrl}/v1/generate/vcard`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  deleteImage(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(`${this.apiUrl}/v1/delete/image`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getQrCodeUrl(args): Observable<any> {
    if (!args.appId) {
      args.appId = this.sharedService.appId;
    }
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(`${this.apiUrl}/v1/contact/list`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getChannelList(appId): Observable<any> {
    const args = { appId: appId || this.sharedService.appId,
         timeZone: this.sharedService.timeZone };
    this.httpOptions1.params = null;
    return this.http
      .post<any>(`${this.apiUrl}/v1/list/channelname`, args, this.httpOptions1)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getConfiguredChannelsList(): Observable<any> {
    const args = { appId: this.sharedService.appId ,
      timeZone: this.sharedService.timeZone};
    this.httpOptions1.params = null;
    return this.http
      .post<any>(`${this.apiUrl}/v1/list/channelname`, args, this.httpOptions1)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getSmartContact(appId): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    )
    .set('appid', appId);
    return this.http
    .get<any>(`${this.apiUrl}/smartcontact/list`, this.httpOptions)
    .pipe(
      tap(),
      catchError((error) => {
        this.expService.handleError(error);
        return of(error);
      })
    );
  }
  getProjectTheme(appid): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appid',
      appid
    );
    return this.http
      .get<any>(`${this.apiUrl}/getAppTheme`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {

          this.expService.handleError(error);
          return of(error);
        })
      );
  }
}
