import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/browser';
import { ExceptionHandlerService } from '../exception-handler.service';

Sentry.init({
    environment: environment.environment,
    dsn: environment.sentryKey,
    ignoreErrors: [
        'Non-Error exception captured'
    ],
    beforeSend(event, hint) {
        if (environment.production) {
        const isNonErrorException =
            event.exception.values[0]?.value?.startsWith('Non-Error exception captured') ||
            hint.originalException['message']?.startsWith('Non-Error exception captured');
        if (isNonErrorException) {
            return null;
        }
        return event;
    }
    }
  });

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private readonly http: HttpClient, private readonly envService: EnvironmentService, private readonly expService: ExceptionHandlerService) {}

    statusApi() {
        const httpOptions = {
            params: new HttpParams().set('key', this.envService.read('statusKey'))
        };
        return this.http.get<any>(`${this.envService.read('apiUrl')}/status`, httpOptions).pipe(
            tap(),
            catchError((error) => {
                return of(error);
            })
        );
    }

}
