import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject} from 'rxjs';
import { EnvironmentService } from '../../../../environment.service';
import { SharedsService } from 'src/app/shareds/shareds.service';

@Injectable({
    providedIn: 'root'
})
export class MsgCenterService {
    apiUrl: string;
    httpOptions: any;
    private readonly agentOnlineFlag = new BehaviorSubject<string>('true');
    cast = this.agentOnlineFlag.asObservable();
    private readonly menuToggleFlag = new BehaviorSubject<boolean>(true);
    menuToggle = this.menuToggleFlag.asObservable();
    private readonly chatBoxMessages = new BehaviorSubject<string[]>([]);
    openMessages = this.chatBoxMessages.asObservable();
    private readonly  msgcenterReloadSubject = new Subject<any>();
    msgcenterReloadSubjectState = this.msgcenterReloadSubject.asObservable();

    sendMsgcenterReloadState(message) {
        this.msgcenterReloadSubject.next(message);
    }

    getMsgcenterReloadState(): Observable<any> {
        return this.msgcenterReloadSubject.asObservable();
    }

    editAgentOnlineFlag(flag) {
        this.agentOnlineFlag.next(flag);
    }

    toggleMenuSize(flag) {
        this.menuToggleFlag.next(flag);
    }

    chatBoxMessage(flag) {
        this.chatBoxMessages.next(flag);
    }

    constructor(private readonly http: HttpClient, private readonly envService: EnvironmentService, private readonly sharedService: SharedsService) {
        this.apiUrl = this.envService.read('apiUrl');
        this.setHttpHeaders();
    }


    listNotifications(args): Observable<any> {
        args.timeZone = this.sharedService.timeZone;
        args.appId = this.sharedService.appId;
        return this.http.post<any>(`${this.apiUrl}/dashboard/notifications/list`,
            args, this.httpOptions).pipe();
    }

    logout(): Observable<any> {
        const args = { appId: this.sharedService.appId, timeZone: this.sharedService.timeZone };
        return this.http.post<any>(`${this.apiUrl}/dashboard/user/logout`,
        args, this.httpOptions).pipe();
    }

    readNotification(args): Observable<object> {
        args.appId = this.sharedService.appId;
        args.timeZone = this.sharedService.timeZone;
        return this.http.post<object>(`${this.apiUrl}/dashboard/notification/read`, args,
            this.httpOptions).pipe();
    }

    detailsNotification(args): Observable<any> {
        args.appId = this.sharedService.appId;
        args.timeZone = this.sharedService.timeZone;
        return this.http.post<any>(`${this.apiUrl}/dashboard/notification/details`, args,
            this.httpOptions).pipe();
    }

    updateSeenNotification(args): Observable<object> {
        args.appId = this.sharedService.appId;
        args.timeZone = this.sharedService.timeZone;
        return this.http.post<object>(`${this.apiUrl}/dashboard/user/notification/update/seen`,
            args, this.httpOptions).pipe();
    }

    getAppNames(args): Observable<any> {
        args.appId = this.sharedService.appId;
        args.timeZone = this.sharedService.timeZone;
        return this.http.post<any>(`${this.apiUrl}/dashboard/user/getappnames`, args,
            this.httpOptions).pipe();
    }

    getAppDetails(args): Observable<any> {
        args.timeZone = this.sharedService.timeZone;
        return this.http.post<any>(`${this.apiUrl}/dashboard/user/getappdetails`, args,
            this.httpOptions).pipe();
    }

    setHttpHeaders() {
        this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: this.envService.read('Authorization'),
            'access-token': this.sharedService.token,
          }),
        };
    }
}
