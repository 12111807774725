import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, tap, switchMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { EnvironmentService } from '../../../environment.service';
import { SharedsService } from 'src/app/shareds/shareds.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
    private readonly sharedService: SharedsService,
    private readonly expService: ExceptionHandlerService
  ) {
    this.apiUrl = this.envService.read('apiUrl');
    this.setHttpHeaders();
  }
  private readonly messageSource = new BehaviorSubject({});
  currentMessage = this.messageSource.asObservable();

  apiUrl: string;
  httpOptions: any;
  searchvalues: any = [];

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  public getDynamicSuggestionssearch(terms): Observable<any> {
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term !== '') {
          this.httpOptions.params = new HttpParams()
            .set('limit', '10')
            .set('skip', '0')
            .set('appId', this.sharedService.appId)
            .set('search', term.toString())
            .set('timeZone', this.sharedService.timeZone);
          return this.http.get<any>(
            `${this.apiUrl}/v1/dashboard/search/questions`,
            this.httpOptions
          );
        } else {
          return this.searchvalues;
        }
      })
    );
  }

  userMessages(args): Observable<any> {
    if (!args?.appAgentHandover) {
     return this.userMessagesFilterDate(args);
    } else {
      this.httpOptions.params = new HttpParams()
        .set('filter', args.filter)
        .set('limit', args.limit)
        .set('skip', args.skip)
        .set('reopen', args.reopen)
        .set('reengage', args.reengage)
        .set('recordcount', args.recordcount)
        .set('engaged', args.engaged ? args.engaged : false)
        .set('engageRequest', args.engageRequest)
        .set('reassigned_agent', args.reassigned_agent)
        .set('agent_id', args.agent_id)
        .set('agentGroupId', args.agentGroupId ? args.agentGroupId : '')
        .set('appId', this.sharedService.appId)
        .set('timeZone', this.sharedService.timeZone);
      return this.http
        .get<any>(
          `${this.apiUrl}/dashboard/user/messages/queue`,
          this.httpOptions
        )
        .pipe(
          tap(),
          catchError((error) => {
            this.expService.handleError(error);
            return of(error);
          })
        );
    }
  }

  userMessagesFilterDate(args) {
    if (args.startDate && args.endDate) {
      this.httpOptions.params = new HttpParams()
        .set('filter', args.filter)
        .set('limit', args.limit)
        .set('skip', args.skip)
        .set('reopen', args.reopen)
        .set('reengage', args.reengage)
        .set('recordcount', args.recordcount)
        .set('engaged', args.engaged ? args.engaged : false)
        .set('engageRequest', args.engageRequest)
        .set('reassigned_agent', args.reassigned_agent)
        .set('appId', this.sharedService.appId)
        .set('timeZone', this.sharedService.timeZone)
        .set('startDate', args.startDate)
        .set('endDate', args.endDate)
        .set('agentGroupId', args.agentGroupId ? args.agentGroupId : '');
      } else {
        this.httpOptions.params = new HttpParams()
        .set('filter', args.filter)
        .set('limit', args.limit)
        .set('skip', args.skip)
        .set('reopen', args.reopen)
        .set('reengage', args.reengage)
        .set('recordcount', args.recordcount)
        .set('engaged', args.engaged ? args.engaged : false)
        .set('engageRequest', args.engageRequest)
        .set('reassigned_agent', args.reassigned_agent)
        .set('appId', this.sharedService.appId)
        .set('timeZone', this.sharedService.timeZone)
        .set('agentGroupId', args.agentGroupId ? args.agentGroupId : '');

    }
    return this.http
      .get<any>(`${this.apiUrl}/dashboard/user/messages`, this.httpOptions)
      .pipe(
          tap(),
          catchError((error) => {
            this.expService.handleError(error);
            return of(error);
          })
      );
  }

  listQuestionscategory(): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    ).set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/automate/question/category/list`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  userMessagesList(args): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('filter', args.filter)
      .set('appId', this.sharedService.appId)
      .set('limit', args.limit)
      .set('skip', args.skip)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/user/messages/${args.id}`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  fileupload(args): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.sharedService.token,
      }),
    };
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/upload/chatbox/attachment`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  messagesTitle(args: string): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    ).set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/user/messagesTitle/${args}`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  engagedUsers(engageId): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('engageId', engageId)
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/dashboard/engaged/users`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  categoryChange(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/user/category/update`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  engagerSetting(engageId): Observable<any> {
    const checkAppId = JSON.parse(localStorage.getItem('user')).appid;
    this.httpOptions.params = new HttpParams()
      .set('engagerId', engageId)
      .set('appId', checkAppId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/dashboard/engaged/users`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  engagerChecking(args): Observable<any> {
    this.httpOptions.params = null;
    const checkAppId = JSON.parse(localStorage.getItem('user')).appid;
    args.appId = checkAppId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
    .post<any>(`${this.apiUrl}/dashboard/user/engage`, args, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  sendMessage(args): Observable<any> {
    this.httpOptions.params = null;
    const checkAppId = JSON.parse(localStorage.getItem('user')).appid;
    args.appId = checkAppId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/user/send/response`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  updateUserChatName(args): Observable<any> {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/profilename/update`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  searchConversation(args): Observable<object> {
    if (args.reopen || args.reengage) {
      this.httpOptions.params = new HttpParams()
        .set('appId', this.sharedService.appId)
        .set('agent_handover', args.isAgentHandover)
        .set('reopen', args.reopen)
        .set('reengage', args.reengage)
        .set('search', args.search)
        .set('timeZone', this.sharedService.timeZone);
    } else {
      this.httpOptions.params = new HttpParams()
        .set('appId', this.sharedService.appId)
        .set('agent_handover', args.isAgentHandover)
        .set('search', args.search)
        .set('timeZone', this.sharedService.timeZone);
    }
    return this.http
      .get<object>(`${this.apiUrl}/dashboard/user_search/`, this.httpOptions)
      .pipe();
  }

  resetUserMessageReadCount(args): Observable<object> {
    this.httpOptions.params = new HttpParams()
      .set('appid', this.sharedService.appId)
      .set('user_id', args.user_id)
      .set('connectionName', args.connectionName)
      .set('channelType', args.channelType)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<object>(`${this.apiUrl}/dashboard/reset/readcount`, this.httpOptions)
      .pipe();
  }

  getTemplateList(): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    ).set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/settings/list/template`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getImageTemplateList(): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('type', 'image')
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/settings/list/template`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getApplicationList(): Observable<any> {
    this.httpOptions.params = new HttpParams().set(
      'appId',
      this.sharedService.appId
    ).set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/settings/list/iMessageApp`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getAppleFormQuestions(): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('type', 'appleform')
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/settings/list/template`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  setHttpHeaders() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.sharedService.token,
      }),
    };
  }

  gpt3FetchAISuggestedResponse(message): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('question', message)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/dashboard/questions/getgpt3?`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  getContacts(): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http.get<any>(`${this.apiUrl}/dashboard/contacts/list`,
      this.httpOptions).pipe(
        tap(),
        catchError((error) => {

          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  fetchNLPSuggestedResponse(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/fetch/nlp/response`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  goToliveAgent(args): Observable<any> {
    this.httpOptions.params = null;
    const checkAppId = JSON.parse(localStorage.getItem('user')).appid;
    args.appId = checkAppId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/user/engageBargin`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getTags(args): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('type', 'appleform')
      .set('timeZone', this.sharedService.timeZone)
      .set('limit', args.limit)
      .set('skip', args.skip);
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/tag/list`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  addUserTags(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/add/users/tags`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  downloadImage(url: string): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('url', url);
    return this.http
      .get<any>(
        `${this.apiUrl}/url-proxy`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  sendPaymentLink(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/user/payment`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
}

