import { Injectable, Injector } from '@angular/core';
import { io } from 'socket.io-client';
import { EnvironmentService } from '../../environment.service';
import { Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class SocketService {
  toastr: ToastrService;
  url;
  socketUrl;
  socket;
  subject = new Subject<any>();
  token: any;
  REDIS_PREFIX: any = '-UnifiedAI';

  constructor(private readonly envService: EnvironmentService, private readonly injector: Injector) {
    this.toastr = this.injector.get<ToastrService>(ToastrService);
    this.url = this.envService.read('apiUrl');
    this.socketUrl = this.envService.read('socketUrl');

  }

  connect() {
    let userData: any = localStorage.getItem('user');
    userData = JSON.parse(userData);
    this.token = userData?.token ?? '';
    this.socket = io(this.socketUrl, {
      path: '/ws',
      transports: ['websocket'],
      query: { token: this.token },
    });
    this.socket?.on('connect', () => {
    });
    this.socket?.on('disconnect', (disconnect) => {

    });
    this.socket?.on('new_message' + this.REDIS_PREFIX, (message) => {
        console.log('new_message', message);
    });

    this.socket?.on('online_agents' + this.REDIS_PREFIX, (message) => {

    });
    this.socket?.on('question_edit' + this.REDIS_PREFIX, (message) => {

    });
  }

  disconnect() {
    this.socket.close();
  }

  getNotification(): Observable<any> {
    return new Observable((observer) => {
      this.socket?.on('new_notification' + this.REDIS_PREFIX, (notification) => {

        const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
        const user_id = user.user_id ? user.user_id : '';
        if (notification?.isChatHandover) {
          localStorage.setItem('isChatHandover', notification?.isChatHandover);
         }
         if (notification?.isChatHandover && (notification?.agent_id.includes(user_id))) {
          let to = true;
          if (to) {
          to = false;
          localStorage.setItem('isRemoveFromMessageList', notification?.isRemoveFromMessageList);
          localStorage.setItem('isRemoveFrom', notification?.isRemoveFromMessageList);
          this.toastr.info('', notification?.message);
          setTimeout(() => to = true, 1600);
          }
         }
        observer.next(notification);
      });
    });
  }

  getMessage(): Observable<any> {

    return new Observable((observer) => {
      this.socket?.on('new_message' + this.REDIS_PREFIX, (message) => {
        observer.next(message);
      });
    });
  }
  getGroupMessage(): Observable<any> {

    return new Observable((observer) => {
      this.socket?.on('internal_message' + this.REDIS_PREFIX, (message) => {
        observer.next(message);
      });
    });
  }
  getQuestionSocket(): Observable<any> {

    return new Observable((observer) => {
      this.socket?.on('question_edit' + this.REDIS_PREFIX, (message) => {
        observer.next(message);
      });
    });
  }
  getOnlineAgents(): Observable<any> {

    return new Observable((observer) => {
      this.socket.on('online_agents' + this.REDIS_PREFIX, (message) => {
        observer.next(message);
      });
    });
  }

  blockedContactsUpdate(): Observable<any> {

    return new Observable((observer) => {
      this.socket.on('update_block_list' + this.REDIS_PREFIX, (message) => {
        observer.next(message);
      });
    });
  }

  getDisengageChatWindow(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('close_chatwindow' + this.REDIS_PREFIX, (disengage) => {
        observer.next(disengage);
      });
    });
  }

  getDisengageReengageButton(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('disable_reengage' + this.REDIS_PREFIX, (disengage) => {

        observer.next(disengage);
      });
    });
  }

  getDisengageReopenButton(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('disable_reopen' + this.REDIS_PREFIX, (disengage) => {

        observer.next(disengage);
      });
    });
  }

  getUserConnectionStatus(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on(
        'connection_complete' + this.REDIS_PREFIX,
        (conncetionStatus) => {
          observer.next(conncetionStatus);
        }
      );
    });
  }

  getRevenueUpdate(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on('revenue-update' + this.REDIS_PREFIX, (revenue) => {
        observer.next(revenue);
      });
    });
  }

  getNewUserRoutingUpdate(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on(
        'NewUserRoutingUpdate' + this.REDIS_PREFIX,
        (newUserRoutingAgentList) => {
          observer.next(newUserRoutingAgentList);
        }
      );
    });
  }

  getReportDownloadSocket(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on(
        'report_download' + this.REDIS_PREFIX,
        (newUserRoutingAgentList) => {
          observer.next(newUserRoutingAgentList);
        }
      );
    });
  }

  getAgentStatusUpdate(): Observable<any> {
    return new Observable((observer) => {
      this.socket.on(
        'AgentStatusUpdate' + this.REDIS_PREFIX,
        (newUserRoutingAgentList) => {
          observer.next(newUserRoutingAgentList);
        }
      );
    });
  }
  getApplicationStatus(): Observable<any> {

    return new Observable((observer) => {
      this.socket.on(
        'app_disable' + this.REDIS_PREFIX,
        (message) => {
          observer.next(message);
        }
      );
    });
  }
}
