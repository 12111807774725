<div class="component-container">
  <div class="heading bColor">
    <h3 style="margin: 0;">Choose Image Layout</h3>
    <button class="close-button" tooltip="Close" (click)="dialogRef.close()">
      <em class="fas fa-times" style="color: black;"></em>
    </button>
  </div>
  <div class="overflow-section" class="ratio-selection">
    <div style="color: red;">
      <h4>*{{'flowBuilder.imageMapLineInfo' | translate}}</h4>
      <h4>*{{'flowBuilder.imageMapLineWidthInfo' | translate}}</h4>
    </div>
    <div class="layout-selection">
      <div class="layout-option" (click)="onLayoutSelect(1)" (keydown)="onLayoutSelect(1)">
        <div class="gray-area"></div>
        <div class="label">
          <input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 1"> 
          <span>layout 1</span>
        </div>
      </div>
      <div class="layout-option" (click)="onLayoutSelect(2)" (keydown)="onLayoutSelect(2)">
        <div class="gray-area">
          <div style="left: 50%;top: 0;bottom: 0;width: 1px;border-left-width: 1px;"></div>
        </div>
        <div class="label">
          <input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 2">
          <span>layout 2</span>
        </div>
      </div>
      <div class="layout-option" (click)="onLayoutSelect(3)" (keydown)="onLayoutSelect(3)">
        <div class="gray-area">
          <div style="left: 0; right: 0; top: 50%; height: 1px; border-top-width: 1px;"></div>
        </div>
        <div class="label">
          <input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 3">
          <span>layout 3</span>
        </div>
      </div>
      <div class="layout-option" (click)="onLayoutSelect(4)" (keydown)="onLayoutSelect(4)">
        <div class="gray-area">
          <div style="left: 0;right: 0;top: 33%;height: 1px;border-top-width: 1px;"></div>
          <div style="left: 0;right: 0;top: 66%;height: 1px;border-top-width: 1px;"></div>
        </div>
        <div class="label">
          <input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 4">
          <span>layout 4</span>
        </div>
      </div>
      <div style="width: 100%;"></div>
      <div class="layout-option" (click)="onLayoutSelect(5)" (keydown)="onLayoutSelect(5)">
        <div class="gray-area">
          <div style="left: 0; right: 0; top: 50%; height: 1px; border-top-width: 1px;"></div>
          <div style="left: 50%; top: 0; bottom: 0; width: 1px; border-left-width: 1px;"></div>
        </div>
        <div class="label">
          <input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 5">
          <span>layout 5</span>
        </div>
      </div>
      <div class="layout-option" (click)="onLayoutSelect(6)" (keydown)="onLayoutSelect(6)">
        <div class="gray-area">
          <div style="left: 0; right: 0; top: 50%; height: 1px; border-top-width: 1px;"></div>
          <div style="left: 50%; top: 50%; bottom: 0; border-left-width: 1px;"></div>
        </div>
        <div class="label">
          <input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 6">
          <span>layout 6</span>
        </div>
      </div>
      <div class="layout-option" (click)="onLayoutSelect(7)" (keydown)="onLayoutSelect(7)">
        <div class="gray-area">
          <div style="left: 0; right: 0; top: 50%; height: 1px; border-top-width: 1px;"></div>
          <div style="left: 0; right: 0; top: 75%; height: 1px; border-top-width: 1px;"></div>
        </div>
        <div class="label">
          <input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 7">
          <span>layout 7</span>
        </div>
      </div>
      <div class="layout-option" (click)="onLayoutSelect(8)" (keydown)="onLayoutSelect(8)">
        <div class="gray-area">
          <div style="left: 0; right: 0; top: 50%; height: 1px; border-top-width: 1px;"></div>
          <div style="left: 33%; top: 0; bottom: 0; width: 1px; border-left-width: 1px;"></div>
          <div style="left: 66%; top: 0; bottom: 0; width: 1px; border-left-width: 1px;"></div>
        </div>
        <div class="label">
          <input type="radio"  name="radio_activity" [checked]="selectedLayoutId == 8">
          <span>layout 8</span>
        </div>
      </div>
    </div>
    <div style="display: flex;">
      <div class="bottom-section">
        <div #imageContainer class="image-container" style="width: 35rem;">
          <img class="image-preview" #imageElement (load)="onImageLoad($event)" [src]="this.dialogData?.['image_url']"
            alt="preview">
          <div class="layout-area">
            <div *ngFor="let area of layoutAreaOptions; let i = index" class="layout-section" #layoutSection
              [style]="area.styles" [class.selected]="area.areaId == selectedLayoutArea?.areaId"
              (click)="selectLayoutArea(area, layoutSection, imageContainer, $event)" (keydown)="selectLayoutArea(area, layoutSection, imageContainer, $event)">
              <button *ngIf="area.text" class="action-button">{{area.text}}</button>
              <video *ngIf="area.videoUrl" class="responsive-attachment" style="width: 100%;height: 100%;" controls>
                <track label="English" kind="captions" srclang="en-us">
                <source [src]="area['videoUrl']">
              </video>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedLayoutArea && !disableImagemap" class="edit-section1">
        <div class="subField">
          <div class="label-text" style="padding-bottom: 10px;"><span>Action on the Layout Section</span> <span (click)="clearField();" (keydown)="clearField();" class="closeAttach" style="cursor: pointer;color: red;    float: right;font-size: 20px;font-weight: 600;">&times;</span></div>
          <select [ngModel]="selectedLayoutArea.action" (ngModelChange)="onActionSelect($event, i)">
            <option value="message">Quick reply</option>
            <option value="uri">Web Url</option>
            <option value="video" *ngIf="!setVideo || selectedLayoutArea.action === 'video'">Video</option>
          </select>
        </div>
        <div *ngIf="selectedLayoutArea.action !== 'video' && selectedLayoutArea.action !== 'uri'" class="subField">
          <div class="label-text">Button Text</div>
          <input type="text" [(ngModel)]="selectedLayoutArea.text" (change)="setInvalid()">
        </div>
        <div *ngIf="selectedLayoutArea.action === 'uri'" class="subField" style="margin-top: 1rem;">
          <div class="label-text">Url</div>
          <input type="text" [(ngModel)]="selectedLayoutArea.linkUri" (change)="setInvalid()">
        </div>
        <div *ngIf="selectedLayoutArea.action === 'video'" style="margin-top: 1rem;" class="subField">
          <div class="label-text">Upload Video</div>
          <div *ngIf="!selectedLayoutArea['videoUrl'] && !uploadloader">
            <label for="file-input" style="cursor: pointer;padding-top: 5px;font-size: 13px;">
              <i class="fas fa-paperclip" aria-hidden="true"></i>
              <span>{{'common.AttachVideo' | translate}}
                <em [popover]="popUpAttach" [placement]="'left'" [container]="'body'" [outsideClick]="true"
                  class="fas fa-info-circle" triggers="mouseenter mouseleave click"></em>
              </span>
            </label>
            <input #fileInput id="file-input" type="file" accept="video/*" (change)="handleFileUpload($event, 'video')"
              class="custom-file-input" style="display: none;">
          </div>
          <div *ngIf="selectedLayoutArea['videoUrl'] && !uploadloader">
            <span><a [href]="selectedLayoutArea['videoUrl']" target="_blank"
                rel="noopener noreferrer">{{selectedLayoutArea['filename']}}</a></span>
            <span (click)="imageCrossClick('video');" (keydown)="imageCrossClick('video');" class="closeAttach"
              style="cursor: pointer;color: red;">&times;</span>
          </div>
          <div style="padding: 5px 5px" *ngIf="uploadloader && fileType === 'video'">
            <img alt="" src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 8%" />
          </div>
        </div>
        <div *ngIf="selectedLayoutArea.action === 'video'" style="margin-top: 1rem;" class="subField">
          <div class="label-text">Preview Image</div>
          <div *ngIf="!selectedLayoutArea['previewImage'] && !uploadloaderImage">
            <label for="file-input_image" style="cursor: pointer;padding-top: 5px;font-size: 13px;">
              <i class="fas fa-paperclip" aria-hidden="true"></i>
              <span>{{'common.Attachfile' | translate}}
                <em [popover]="popUpImage" [placement]="'left'" [container]="'body'" [outsideClick]="true"
                  class="fas fa-info-circle" triggers="mouseenter mouseleave click"></em>
              </span>
            </label>
            <input #fileInput id="file-input_image" type="file" accept="image/*"
              (change)="handleFileUpload($event, 'image')" class="custom-file-input" style="display: none;">
          </div>
          <div *ngIf="selectedLayoutArea['previewImage'] && !uploadloaderImage">
            <span><a [href]="selectedLayoutArea['previewImage']" target="_blank"
                rel="noopener noreferrer">{{selectedLayoutArea['previewImageName']}}</a></span>
            <span (click)="imageCrossClick('image');" (keydown)="imageCrossClick('image');" class="closeAttach"
              style="cursor: pointer;color: red;">&times;</span>
          </div>
          <div style="padding: 5px 5px" *ngIf="uploadloaderImage && fileType === 'image'">
            <img alt="" src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 8%" />
          </div>
        </div>
        <div style="text-align: right;padding-top: 10px;"><button [disabled]="disableImagemap" class="commonButton"
            (click)="updateLayoutData();">Done</button></div>
      </div>
    </div>
    <div style="text-align: center;padding-bottom: 15px;">
      <button class="btn-add" [disabled]="disableImagemap"
        [ngClass]="(disableImagemap) ? 'btnDisable submitButton' : 'commonButton nodeSubmit'" (click)="updateImageMap()">
        {{'common.update' | translate}}
      </button>
    </div>
  </div>
</div>
<ng-template #popUpAttach>
  <div class="card" style="border:none;max-height: 250px;">
    <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
      <p class="card-text" style="font-size: 12px;" innerHtml="{{'common.attachment_video_info' | translate}}">
      </p>
    </div>
  </div>
</ng-template>
<ng-template #popUpImage>
  <div class="card" style="border:none;max-height: 250px;">
    <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
      <p class="card-text" style="font-size: 12px;" innerHtml="{{'common.attachment_info_img' | translate}}">
      </p>
    </div>
  </div>
</ng-template>