
import {switchMap, distinctUntilChanged, debounceTime,  catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'src/app/environment.service';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpBackend } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SharedsService } from '../../../shareds/shareds.service';
import { ExceptionHandlerService } from 'src/app/exception-handler.service';

@Injectable({
  providedIn: 'root',
})
export class FlowBuilderService {
  // private http: HttpClient;
  apiUrl: string;
  httpOptions: any;
  searchvalues: any = [];
  argument: any;
  token: any;
  private readonly http: HttpClient;
  userData: any;
  private readonly dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();
  constructor(
    handler: HttpBackend,
    private readonly envService: EnvironmentService,
    private readonly sharedService: SharedsService,
    private readonly expService: ExceptionHandlerService
  ) {
    this.http = new HttpClient(handler);
    this.userData = JSON.parse(localStorage.getItem('user'))
      ? JSON.parse(localStorage.getItem('user'))
      : {};
    this.token = this.userData.token ? this.userData.token : '';
    this.apiUrl = this.envService.read('apiUrl');
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.envService.read('Authorization'),
        'access-token': this.token,
      }),
    };
  }
  updateData(data: any) {
    this.dataSubject.next(data);
  }
  public getDynamicSuggestionssearch(terms): Observable<any> {
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term !== '') {
          this.httpOptions.params = new HttpParams()
            .set('limit', '10')
            .set('skip', '0')
            .set('appId', this.sharedService.appId)
            .set('search', term.toString())
            .set('timeZone', this.sharedService.timeZone);
          return this.http.get<any>(
            `${this.apiUrl}/v1/dashboard/search/questions`,
            this.httpOptions
          );
        } else {
          return this.searchvalues;
        }
      })
    );
  }

  createNewFlow(args): Observable<any> {
    const userData = JSON.parse(localStorage.getItem('user'));
    args.appId = userData?.appid ?? '';
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/add/flows`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  flowDisconnect(args): Observable<any> {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/disconnect/flow/nodes`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  verifyFolwFlow(args): Observable<any> {
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/v1/dashboard/link/flow/dq`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  FlowbuilderTreeNode(flowbuilderId): Observable<any> {
    this.httpOptions.params = null;
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/v1/dashboard/list/flowspreview/${flowbuilderId}`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getVariablesList(args): Observable<any> {
    this.httpOptions.params = null;
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone)
      .set('variableType', args.variableType);
    return this.http
      .get<any>(`${this.apiUrl}/dashboard/list/all/variable`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  getEntitylist(): Observable<any> {
    this.httpOptions.params = null;
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/list/entities`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  listNewFlow(arg): Observable<any> {
    const dateFormat = this.userData.dateFormat
      ? this.userData.dateFormat
      : 'MM/dd/YYYY';
    this.httpOptions.params = null;
    this.httpOptions.params = new HttpParams()
      .set('limit', arg.limit)
      .set('skip', arg.skip)
      .set('language', arg.language)
      .set('dateFormat', dateFormat)
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/dashboard/list/flows`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  channelListflow(): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/v1/dashboard/list/flow/channels`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  updateFlow(args): Observable<any> {
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/v1/dashboard/update/flows`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  deleteText(args): Observable<any> {
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/v1/dashboard/delete/flow/nodes`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  deleteFlow(args): Observable<any> {
    this.httpOptions.params = null;
    args.timeZone = this.sharedService.timeZone;
    args.appId = this.sharedService.appId;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/delete/flows`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  editStatusFlow(args) {
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/edit/flows`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  copyStatusFlow(args) {
    this.httpOptions.params = null;
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/copy/flows`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  languageSelector(): Observable<any> {
    this.httpOptions.params = new HttpParams()
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/questions/language_selector`,
        this.argument,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  searchEntries(term, arg) {
    this.httpOptions.params = new HttpParams()
      .set('search', term)
      .set('limit', arg.limit)
      .set('skip', arg.skip)
      .set('language', arg.language)
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(`${this.apiUrl}/dashboard/search/flows`, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  flowbuilderFlow(flowbuilderId, pagearg) {
    this.httpOptions.params = new HttpParams()
      .set('limit', pagearg.limit)
      .set('skip', pagearg.skip)
      .set('appId', this.sharedService.appId)
      .set('timeZone', this.sharedService.timeZone);
    return this.http
      .get<any>(
        `${this.apiUrl}/v1/dashboard/list/flow/nodes/${flowbuilderId}`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  botsaysAddText(args) {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/v1/dashboard/add/flow/nodes`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  botsaysEditText(args) {
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/v1/dashboard/edit/flow/nodes`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  AWSPolicy(file) {
    const arg = {
      file_name: file.name,
      mime_type: file.type,
      appId: this.sharedService.appId,
      timeZone: this.sharedService.timeZone,
    };
    this.httpOptions.params = null;
    return this.http
      .post<any>(`${this.apiUrl}/v1/generate/s3/policy`, arg, this.httpOptions)
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  uploadfile(imageFile, s3data) {
    const fd = new FormData();
    fd.append('key', s3data.fields.key);
    fd.append('Content-Type', imageFile.type);
    fd.append('acl', 'public-read');
    fd.append('policy', s3data.fields.Policy);
    fd.append('X-Amz-Algorithm', s3data.fields['X-Amz-Algorithm']);
    fd.append('X-Amz-Credential', s3data.fields['X-Amz-Credential']);
    fd.append('X-Amz-Date', s3data.fields['X-Amz-Date']);
    fd.append('X-Amz-Signature', s3data.fields['X-Amz-Signature']);
    fd.append('file', imageFile);
    const req = new HttpRequest(
      'POST',
      `https://${s3data.fields.bucket}.s3.amazonaws.com/`,
      fd,
      { responseType: 'text' }
    );

    return this.http.request(req);
  }
  saveFlowBuilderData(args) {
    args.appId = this.sharedService.appId;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/flow/nodes/save`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  flowBuilderData(flow_id, type: any = 'All', fromDate: any = 'null', toDate: any = 'null') {
    if (type === 'All') {
      this.httpOptions.params = new HttpParams().set('appId', this.sharedService.appId);

    } else {
  this.httpOptions.params = new HttpParams().set('appId', this.sharedService.appId).set('startDate', fromDate).set('endDate', toDate);
}
    return this.http
      .get<any>(
        `${this.apiUrl}/dashboard/list/flow/nodes/${flow_id}`,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
  }

  apiValidate(args) {
    args.timeZone = this.sharedService.timeZone;
    args.appId = this.sharedService.appId;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/validate/flow/apiurl`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  addNode(args: any) {

    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
      if (args.subNode) {
        args.subNode[0].appId = this.sharedService.appId;
      }
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/flow/nodes/add`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  deleteNode(args) {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    if (args.subNode) {
      args.subNode[0].appId = this.sharedService.appId;
    }
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/flow/nodes/delete`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  updateNodePosition(args) {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    if (args.subNode) {
      args.subNode[0].appId = this.sharedService.appId;
    }
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/flow/nodes/position-change`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          return of(error);
        })
      );
  }

  nodeEdgesAdd(args) {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    if (args.subNode) {
      args.subNode[0].appId = this.sharedService.appId;
    }
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/flow/nodes/link/add`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  deleteEdges(args: any) {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    if (args.subNode) {
      args.subNode[0].appId = this.sharedService.appId;
    }
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/flow/nodes/link/delete`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  flowSubmit(args) {
    args.forEach((element) => {
      element.appId = this.sharedService.appId;
      element.timeZone = this.sharedService.timeZone;
      if (element.subNode) {
        element.subNode[0].appId = this.sharedService.appId;
      }
      return element.appId;
    });
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/add/flow/nodes`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  flowNodeDataSubmit(args) {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    if (args.subNode) {
      args.subNode[0].appId = this.sharedService.appId;
    }
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/flow/nodes/update`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
  flowDelete(args) {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/dashboard/delete/flow/nodes`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  exportCsvFlow(file: any, flowmodel: any, language: any) {
    const formData = new FormData();
    formData.append('appId', this.sharedService.appId);
    formData.append('language', language);
    formData.append('flowName', flowmodel.name);
    formData.append('file', file, file.name);
    this.httpOptions.params = null;
    const headers = {
      headers: new HttpHeaders({
        Authorization: this.envService.read('Authorization'),
        'access-token': this.token,
      }),
      params: null
    };
    return this.http
      .post<any>(
        `${this.apiUrl}/flow/import`,
        formData,
        headers
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }

  pasteNode(args: any) {
    args.appId = this.sharedService.appId;
    args.timeZone = this.sharedService.timeZone;
    if (args.subNode) {
      args.subNode[0].appId = this.sharedService.appId;
    }
    this.httpOptions.params = null;
    return this.http
      .post<any>(
        `${this.apiUrl}/flow/nodes/copy-paste`,
        args,
        this.httpOptions
      )
      .pipe(
        tap(),
        catchError((error) => {
          this.expService.handleError(error);
          return of(error);
        })
      );
  }
}
