import { Component, OnInit } from '@angular/core';
import { ConversationService } from '../conversation.service';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { IconsService } from '../../shared/icons.service';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss', '../conversation.component.scss']
})
export class ListComponent implements OnInit {

  messageTitle: any;
  userId: string;
  conversations: Array<any> = [];
  // Pagination
  page = 1;
  totalCount = 0;
  paginationDisabled = false;


  constructor(private readonly conversationService: ConversationService
    , private readonly route: ActivatedRoute
    , private readonly iconsService: IconsService) { }

  ngOnInit() {
    this.userId = this.route.snapshot.paramMap.get('id');
    this.paginationDisabled = true;
    this.conversationService.messagesTitle(this.userId).subscribe(data => {
      this.paginationDisabled = false;
      this.messageTitle = data.info ? data.info : null;
      this.listMessages();
    });
  }

  listMessages(): void {
    const args = {
      filter: 'All',
      limit: 10,
      skip: (this.page * 10) - 10,
      id: this.userId
    };
    this.paginationDisabled = true;
    this.conversationService.userMessagesList(args).subscribe(response => {
      this.paginationDisabled = false;
      this.totalCount = response?.info?.totalCount ?? 0;

      this.conversations = _.filter(response.info, (value, key) => {
        return key !== 'totalCount';
      });
    });

  }

  applyFontIcon(channel): string[] {
    return this.iconsService.applyChannelIcon(channel);
  }

  applyTypeIcon(type): string[] {
    return this.iconsService.applyMediaIcon(type);
  }

  pageChanged(page): void {
    this.page = page;
    this.listMessages();
  }
}
