<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;">
    <div class="page-loader" *ngIf="loadershow">
        <img src="assets/images/dashboard/uib-loading-500x500.gif" style="width: 100px" alt="">
    </div>

    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8" style="padding-left:0px;">
        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10" style="padding-left:0px;">
            <app-page-header i18n-pageTitle="@@contacts_title" pageTitle="{{'richMenuList.title' | translate}}"
                EngTitle="flex"></app-page-header>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 plusButtons" style="padding-right: 0%;">
            <button id="trial_usersAddBtn" [ngClass]="addbutton==true ? 'btnDisable': 'commonButton'"
                (click)="addRichMenuButton()" class="customButtonStyle commonButton">
                <em class="fa fa-plus"></em>
            </button>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px;text-align: right;margin-bottom: 3%">
            <form class="search">
                <div class="search__wrapper">
                    <input id="trial_userSerch" type="text" [(ngModel)]="search"
                        (keyup)="searchRichMenu(($event.target['value']))" name=""
                        placeholder="{{'common.search_for' | translate}}" class="search__field "
                        name="add_new_variant_question">
                    <button id="trial_userSerchSub" type="submit" class="fa fa-search search__icon"></button>
                </div>
            </form>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0px 0px 10px 0px;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive" style="padding:0px;">
                <table aria-labelledby="contactList1" mat-table matSort class="contactTable" [dataSource]="richMenuList"
                    style="overflow: hidden;">
                    <ng-container matColumnDef="name">
                        <th id="title" mat-header-cell *matHeaderCellDef i18n="@@matTab_name"> {{'common.name' |
                            translate}}
                        </th>
                        <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.name}}">
                            {{element.name ? element.name : ''}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Added By">
                        <th id="creator_name" mat-header-cell *matHeaderCellDef i18n="@@matTab_userIdentifier">
                            {{'common.added_by' | translate}}</th>
                        <td mat-cell *matCellDef="let element" style="cursor:default;" title="{{element.creator_name}}">
                            {{element.creator_name ? element.creator_name : ''}} </td>

                    </ng-container>

                    <ng-container matColumnDef="Last Modified">
                        <th id="lastmodified" mat-header-cell *matHeaderCellDef i18n="@@matTab_lastSeen">
                            {{'common.last_modified' |
                            translate}}
                        </th>
                        <td mat-cell style="cursor: pointer;" *matCellDef="let element" title="{{element.modified_on}}">
                            <span style="cursor:default;" title="{{element.modified_on}}">
                                {{element.created_on | date:dateFormat3}}</span>
                        </td>

                    </ng-container>
                    <ng-container matColumnDef="Status">
                        <th id="status" mat-header-cell *matHeaderCellDef i18n="@@matTab_lastSeen">
                            {{'common.status' |
                            translate}}</th>
                        <td mat-cell *matCellDef="let element" style="cursor:default;">
                            {{element.setting === 'channel' ? 'Set for channel' : (element.setting === 'user' ? 'Set for
                            user' : '-')}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="manage">
                        <th id="manage" mat-header-cell style="text-align:center;" *matHeaderCellDef> {{'common.manage'
                            | translate}}</th>
                        <td mat-cell style="font-size: 14px;padding-left: 50px;" *matCellDef="let element"
                            data-toggle="modal" data-target="#myModal">
                            <!-- <em class="fa fa-edit" title="Edit template" (click)="editRichMenu(element)"
                                (keydown)="editRichMenu(element)" style="cursor: pointer;"></em> -->
                                <em class="fa fa-eye" title="View Rich Nenu" (click)="editRichMenu(element)"
                                (keydown)="editRichMenu(element)" style="cursor: pointer;"></em>
                            &nbsp;&nbsp;
                            <em class="far fa-trash-alt" (click)="openConfirmationDialog(element)"
                                (keydown)="openConfirmationDialog(element)" style="cursor: pointer;"></em>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{'highlight': selecteUser._id == row._id}" (click)="showRichDetails(row)"
                        (keydown)="showRichDetails(row)"></tr>
                </table>
            </div>

            <div class="row row-bottom" style="text-align:center;" *ngIf="( !richMenuList?.length) && !loadershow">
                <p class="no-msg" i18n="@@noRecordsfound">{{'common.no_records_found' | translate}}</p>
            </div>
            <app-pagination [collectionSize]="totalCount" [noPerPage]="itemsPerPage" [(page)]="pagelist" [maxSize]="3"
                (pageChange)="pageChange($event)"></app-pagination>
        </div>

    </div>
    <div *ngIf="isOpen" class="col-lg-4 col-md-4 col-sm-4 col-xs-4 rightBar"
        [ngStyle]="{'display':checkWindowSize() ? 'none':'', 'width': (leftMargin !=='270px') ? '27%':''}"
        style="overflow-y:scroll;height: 93vh">
        <div style="text-align:justify;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="display:inline-flex;">
                <h4 class="col-lg-8 col-md-8 col-sm-8 col-xs-8 viewHeading" *ngIf="action == 'View'"
                    style="cursor:context-menu;">View {{actionHeader}}</h4>
                <h4 class="col-lg-9 col-md-9 col-sm-9 col-xs-9" *ngIf="action !== 'View'" style="cursor:context-menu;">
                    Update {{actionHeader}}</h4>
                <div *ngIf="action == 'View'" class="col-lg-3 col-md-3 col-sm-3 col-xs-3 actionButton"
                    style="float:right;padding:0px;top: 20px;">
                    <button id="quickreplyActionBtnLs"
                        class="commonButton customButtonStyle dropdown-toggle lang-dropdown"
                        style="width:100%;text-align:center;padding: 6px;display: inline-flex;font-size: 12px;font-family: 'WorkSansMedium', serif; height:30px;"
                        type="button" data-toggle="dropdown">
                        {{'common.ACTIONS' | translate}} &nbsp;&nbsp;<em class="fas fa-chevron-down"
                            style="margin-top: 4%;"></em>
                    </button>
                    <ul class="dropdown-menu">
                        <li style="width: 100%;" class="actionMenuStyle" (click)="update()" (keydown)="update()">
                            {{'common.edit' | translate}}
                        </li>
                    </ul>
                </div>
            </div>
            <form class="form-body" style="padding-bottom: 7% !important;">
                <div class="form-group" style="padding-bottom: 3%;">
                    <span style="font-size: 12px;color: #828282;margin-bottom: 4px;font-weight:500;"> {{'common.name'
                        |
                        translate}}</span>
                    <span *ngIf="action == 'View' || action == 'Update'"><br /><span
                            style="font-size: 14px;font-weight:500;">{{selecteUser.name
                            ? selecteUser.name : ''}}</span></span>
                </div>

                <div class="form-group" style="padding-bottom: 3%;display:grid;">
                    <span
                        style="font-size: 12px;color: #828282;margin-bottom: 4px;font-weight:500;">{{'richMenuList.richId'
                        | translate}}</span>
                    <span *ngIf="action == 'View' || action == 'Update'"><span
                            style="font-size: 14px;font-weight:500;">{{selecteUser.richMenuId
                            ? selecteUser.richMenuId :
                            '-'}}</span></span>
                </div>
                <div class="form-group" style="padding-bottom: 3%;display:grid;">
                    <span
                        style="font-size: 12px;color: #828282;margin-bottom: 4px;font-weight:500;">{{'common.status' | translate}}</span>
                    <span *ngIf="action == 'View'"><span style="font-size: 14px;font-weight:500;">{{selecteUser.setting === 'channel' ? 'Set for channel' : (selecteUser.setting === 'user' ? 'Set for
                        user' : '-')}}</span></span>
                    <span *ngIf="action == 'Update'">
                        <span style="padding-right: 20px;">
                            <input type="radio" id="channel" name="channel" value="channel" [(ngModel)]="setting">
                            <label class="question-form-label" for="channel">{{'richMenuList.setChannel'
                                | translate}}</label>
                        </span>
                        <span>
                            <input type="radio" id="user" name="user" value="user" [(ngModel)]="setting">
                            <label class="question-form-label" for="user">{{'richMenuList.setUser'
                                | translate}}</label>
                        </span>
                    </span>
                    <div>
                        <input id="richUserList" *ngIf="setting === 'user' && action == 'Update'" type="text"
                            class="form-control form-cotrol-question"
                            style="font-size: 14px;padding-top: 0px !important;height: 30px;padding-bottom: 0px !important;box-shadow:none !important;width: 72%;"
                            placeholder="Press enter to add user ID" [ngModelOptions]="{standalone:true}" [(ngModel)]="userKey"
                            (keydown)="addUserId($event)" />
                    </div>
                </div>
                <div class="form-group" *ngIf="setting === 'user' && action == 'View' && richUserList.length"
                    style="padding-bottom: 3%;display:grid;padding-top: 10px;">
                    <span style="font-size: 12px;color: #828282;margin-bottom: 4px;font-weight:500;">{{'richMenuList.userList'
                        | translate}}</span>
                    <div class="d-inline-block" *ngFor="let key of richUserList">
                        <div class="btn btn-outline-primary" style="padding-left: 0px;" id="dropdownBasic3">
                            {{key}}
                        </div>
                    </div>
                </div>
                <div *ngIf="setting === 'user' && action == 'Update'">
                    <div class="d-inline-block" *ngFor="let key of richUserList">
                        <button class="btn btn-outline-primary" id="dropdownBasic3">
                            {{key}}
                            <span id="closeUser" type="button" style="color: red;" (keydown)="removeuserId(key)" (click)='removeuserId(key)'>&times;</span>
                        </button>
                    </div>
                </div>
                <div class="form-group" *ngIf="action == 'Update'" style="padding-top: 10px;padding-bottom: 3%;display:grid;">
                        <span style="font-size: 12px;color: #828282;margin-bottom: 4px;font-weight:500;">{{'richMenuList.configure' | translate}}</span>
                        <div *ngIf="selecteUser.status === true">
                        <ui-switch size="small" style="margin-left: 10px;" class="uiSwitch" switchColor="white" defaultBoColor="#cfcfcf"
                            [(ngModel)]="selecteUser.status" [ngModelOptions]="{standalone:true}" (change)="onSwitchChanged($event)">
                        </ui-switch>
                        <em [popover]="popUpswitch" [placement]="'right'" [container]="'body'" [outsideClick]="true" style="padding-left: 10px;top: -3px;position: relative;"
                        class="fas fa-info-circle" triggers="mouseenter mouseleave click"></em>
                        </div>

                    <div *ngIf="selecteUser.status === false">
                        <ui-switch size="small" style="margin-left: 10px;" class="uiSwitch" switchColor="white" defaultBoColor="#cfcfcf" disabled
                            [(ngModel)]="selecteUser.status" [ngModelOptions]="{standalone:true}" (change)="onSwitchChanged($event)">
                        </ui-switch>
                    </div>
                </div>
                <button id="trial_userAction" *ngIf="action == 'Update'" class="commonButton btn" type="submit"
                    style="margin-right: 15px;border-radius: 8px;width: 40%;padding: 1%;"
                    (click)="updateRichRow()">{{'common.update' | translate}}</button>
                <button id="trial_userCancel1" *ngIf="action == 'Update'" type="reset"
                    class="cancelButton btn btn-default"
                    style="margin-right: 15px;border-radius: 8px;width: 40%;padding: 1%;"
                    (click)="onCancel(); ">{{'common.cancel' |
                    translate}}</button>
            </form>
        </div>
    </div>

</div>
<ng-template #popUpswitch>
	<div class="card" style="border:none;max-height: 250px;">
	  <div class="card-body" style="padding: 0px 0px 5px 0px !important;height:15%;">
		<p class="card-text" style="font-size: 12px;" innerHtml="{{'richMenuList.infoSwitch' | translate}}">
		</p>
	  </div>
	</div>
  </ng-template>
