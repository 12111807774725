import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { IconsService } from '../../shared/icons.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss'],
})
export class OverviewCardComponent implements OnInit {
  @Input() type;
  @Input() value;
  @Input() value1;
  @Input() title;

  constructor(private readonly iconsService: IconsService, public translate: TranslateService) {
    this.translatorLanguage = localStorage.getItem('Language');
    this.translate.setDefaultLang('en');
    this.translate.use(this.translatorLanguage ? this.translatorLanguage : 'en');
  }

  nlpwithAgent: any;
  chooseType: {
    name: string;
    className: string;
    iClass: string;
    value: string;
    title: string;
    valueSymbol: string;
    tooltip: string;
  };
  translatorLanguage: any;
  types: Array<{
    name: string;
    className: string;
    iClass: string;
    label: string;
    value: string;
    title: string;
    valueSymbol: string;
    tooltip: string;
  }> = [
    {
      name: 'longestHandling',
      className: 'overview-card-intent',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.longestHandling'),
      label: 'Longest handling time',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.longestHandling_tooltip')
    },
    {
      name: 'avg_conversation',
      className: 'overview-card-things',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.avg_conversation'),
      label: 'Avg no of messages per session',
      title: '',
      valueSymbol: '',
      tooltip: ''
    },
    {
      name: 'conversation',
      className: 'overview-card-conversation',
      iClass: 'fa fa-comment',
      value: this.translate.instant('dashboard.conversation'),
      label: 'Sessions',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.conversation_tooltip'),
    },
    {
      name: 'handling',
      className: 'overview-card-intent',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.handling'),
      label: 'Handling time',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.handling_tooltip')
    },
    {
      name: 'response',
      className: 'overview-card-things',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.response'),
      label: 'First response time',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.response_tooltip')
    },
    {
      name: 'messages',
      className: 'overview-card-conversation',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.messages'),
      label: 'Messages',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.messages_tooltip')
    },
    {
      name: 'activeSessions',
      className: 'overview-card-things',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.activeSessions'),
      label: 'Active sessions',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.activeSessions_tooltip')
    },
    {
      name: 'conversation_segregation',
      className: 'tile wide qoute overview-card-conversation',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.conversation_segregation'),
      label: 'Number of sessions handled',
      title: '',
      valueSymbol: '',
      tooltip: ''
    },
    {
      name: 'static_segregation',
      className: 'tile wide resource  overview-card-intent',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.static_segregation'),
      label: 'Number of sessions handled',
      title: '',
      valueSymbol: '',
      tooltip: ''
    },
    {
      name: 'activeConversation',
      className: 'overview-card-things',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.activeConversation'),
      label: 'Active Conversations',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.activeConversation_tooltip')
    },
    {
      name: 'activeUsers',
      className: 'overview-card-things',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.activeUsers'),
      label: 'Active Users',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.activeUsers_tooltip')
    },
    {
      name: 'activeChannels',
      className: 'overview-card-things',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.activeChannels'),
      label: 'Active Channels',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.activeChannels_tooltip')
    },
    {
      name: 'conversationElements',
      className: 'overview-card-things',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.conversationElements'),
      label: 'Content Elements',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.conversationElements_tooltip')
    },
    {
      name: 'contactLists',
      className: 'overview-card-things',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.contactLists'),
      label: 'Audience',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.contactLists_tooltip')
    },
    {
      name: 'messageTemplates',
      className: 'overview-card-things',
      iClass: 'fa fa-info-circle',
      value: this.translate.instant('dashboard.messageTemplates'),
      label: 'Message Templates',
      title: '',
      valueSymbol: '',
      tooltip: this.translate.instant('dashboard.messageTemplates_tooltip')
    }
  ];
  selectedFlipCard: any = {
    name: '',
    className: '',
    iClass: '',
    value: '',
    title: '',
    valueSymbol: '',
    tooltip: ''
  };

  ngOnInit() {
    this.chooseType = _.find(this.types, { name: this.type });

    this.nlpwithAgent = this.check_NLPwithAgent();
  }

  getMenuIcon(name) {
    if (name) {
      return this.iconsService.getMenuIcon(name);
    } else {
      return '';
    }
  }

  flipCard(card) {
    this.selectedFlipCard = card;
  }

  cancelFlip() {
    this.selectedFlipCard = {
      name: '',
      className: '',
      iClass: '',
      value: '',
      title: '',
      valueSymbol: '',
      tooltip: ''
    };
  }

  check_NLPwithAgent() {
    const projectDetails = JSON.parse(localStorage.getItem('user')).appSettings.projectDetail || [];
    if ((projectDetails.NLP && projectDetails.agent_handover) || (projectDetails.NLP && projectDetails.group_messaging)) {
      return true;
    } else {
      return false;
    }
  }
}
