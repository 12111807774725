import {Component, HostListener, Injector, OnInit} from '@angular/core';
import { RichMenuService } from '../rich-menu.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
declare let Swal: any;
@Component({
  selector: 'app-richmenu-list',
  templateUrl: './richmenu-list.component.html',
  styleUrls: ['./richmenu-list.component.scss']
})
export class RichmenuListComponent implements OnInit {
  user: any;
  appId: any;
  loadershow: boolean;
  richMenuList: any;
  totalCount: any;
  displayedColumns: string[] = [
    'name',
    'Added By',
    'Last Modified',
    'Status',
    'manage'
  ];
  search = '';
  pagelist = 1;
  selecteUser: any;
  router: Router;
  user_id: any;
  toastr: ToastrService;
  isOpen: boolean;
  screenWidth: number;
  action = 'View';
  setting = ''
  actionHeader = 'Rich Menu';
  richUserList = [];
  deleteUserList = [];
  userKey = '';
  translate: TranslateService;
  addbutton = false;
  dateFormat3: any;
  screenHeight: number;
  itemsPerPage: number;

  constructor(private readonly injector: Injector, private readonly richMenuService: RichMenuService) {
    this.router = this.injector.get<Router>(Router);
    this.toastr = this.injector.get<ToastrService>(ToastrService);
    this.translate = this.injector.get<TranslateService>(TranslateService);
  }

  ngOnInit() {
    this.loadershow = true;
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.itemsPerPage = this.getScreenHeightValue(this.screenHeight);
    this.user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : {};
    this.appId = this.user.appid ? this.user.appid : '';
    this.user_id = this.user.user_id ? this.user.user_id : '';
    this.loadRichList();
    this.dateFormat3 = this.user.dateFormat ? this.user.dateFormat : 'MM/dd/YYYY';

  }

  // HostListener listens to window resize events
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenHeight = window.innerHeight;
    this.itemsPerPage = this.getScreenHeightValue(this.screenHeight);
    this.loadRichList();
  }

  getScreenHeightValue(h): number {
    return h > 1700 ? 40 : Math.max(Math.round((h * 4) / 300), 10);
  }
  addRichMenuButton() {
    this.router.navigate([environment.dashboardPrefix + '/richmenu']);
  }

  listRichMenu(arg) {
    this.richMenuService.listRichMenu(arg).subscribe(
      (data) => {
        this.loadershow = false;
        this.richMenuList = data?.info ?? [];
        this.totalCount = data?.totalcount;
        if (this.richMenuList && this.richMenuList.length > 0) {
          this.showRichDetails(this.richMenuList[0]);
        } else {
          this.isOpen = false;
        }
      });
  }
  searchRichMenu(value) {
    this.pageChange(1);
  }
  pageChange(page) {
    this.pagelist = page;
    const args1 = {
      limit: this.itemsPerPage,
      skip: this.pagelist * this.itemsPerPage - this.itemsPerPage,
      search: this.search,
    };
    this.listRichMenu(args1);
  }
  updateRichRow() {
    this.loadershow = true;
    let args1 = {
      '_id': this.selecteUser?._id,
      'name': this.selecteUser?.name,
      'richMenuId': this.selecteUser?.richMenuId,
      'user_id': this.user_id,
    };
    if(!this.selecteUser?.status && this.selecteUser?.setting) {
      args1 = this.onSwitchOffUpdate(args1);
    } else {
      args1 =this.setUpdateArgs(args1);
    }
    this.updateRichMenu(args1);
  }
  setUpdateArgs(args1) {
    const newUserList = this.richUserList?.filter(item => !this.selecteUser?.userList?.includes(item));
    if(this.setting === 'user' && !this.richUserList.length) {
      this.toastr.error('', this.translate.instant('richMenuList.user_empty_msg'));
      this.loadershow = false;
      return;
    }
    if (this.setting !== this.selecteUser?.setting || newUserList.length || this.deleteUserList) {
      args1['richUserList'] = this.richUserList;
      args1['newUserList'] = newUserList;
      args1['deleteUserList'] = this.deleteUserList;
    }
    if(this.selecteUser?.setting === 'channel' && this.setting === 'user') {
      args1['unsetChannel'] = true;
    }
    args1['setting'] = this.setting;
    return args1;
  }
  updateRichMenu(args) {
    this.action = 'View';
    this.richMenuService.updateRichMenu(args).subscribe(response => {
      if (response.status === 200) {
        this.toastr.success('', response.info);
        this.loadRichList();
      } else {
        this.toastr.error('', response.info);
        this.loadershow = false;
      }
    });
  }
  showRichDetails(element) {
    this.isOpen = true;
    this.selecteUser = element;
    this.selecteUser.status = !!this.selecteUser.setting;
    this.setting = this.selecteUser.setting;
    this.richUserList = JSON.parse(JSON.stringify(this.selecteUser.userList || []));
    this.action = 'View';
  }
  checkWindowSize() {
    if (this.screenWidth > 992) {
      return false;
    } else {
      return true;
    }
  }
  update() {
    this.action = 'Update';
    this.deleteUserList = [];
    this.selecteUser.status = !!this.selecteUser.setting;
  }
  addUserId(event) {
    if (event.key === 'Enter' && !this.onlySpaces(this.userKey)) {
      this.richUserList.push(this.userKey?.trim());
      this.userKey = '';
    }
  }
  removeuserId(value) {
    this.richUserList = this.richUserList.filter((key) => key !== value);
    if (this.selecteUser?.userList.includes(value)) {
      this.deleteUserList.push(value);
    }
  }
  onlySpaces(str) {
    return str.trim().length === 0;
  }
  onCancel() {
    this.isOpen = false;
    this.action = 'View';
    if (this.richMenuList && this.richMenuList.length > 0) {
      this.showRichDetails(this.richMenuList[0]);
    }
  }
  editRichMenu(obj) {
    this.router.navigate([environment.dashboardPrefix + '/richmenu'], { state: { action: 'edit' , data: obj} });
  }
  openConfirmationDialog(obj) {
    Swal.fire({
      title:
        '<i class="fa fa-exclamation-triangle" style="color:red" aria-hidden="true"></i> &nbsp;Are you sure you want to delete this?',
      showCancelButton: true,
      customClass: 'swal-wide',
      confirmButtonColor: '#333333',

    }).then((result) => {
      if (result.value) {
        this.deleteRowData(obj);
      }
    });
  }
  deleteRowData(row_obj) {
    this.loadershow = true;
    const args = {
      '_id': row_obj._id,
      richMenuId: row_obj.richMenuId
    };
    this.richMenuService.deleteRichMenu(args).subscribe(
      (data) => {
        if (data.status !== 200) {
          this.toastr.error('', data.info);
          this.loadershow = true;
          this.loadRichList();
        } else {
          this.toastr.info('', data.info);
          this.loadRichList();
        }
      },
      (error) => {
        this.toastr.error('', this.translate.instant('toastr.something_went_wrong'));
        this.loadRichList();
      }
    );
  }
  loadRichList() {
    const arge = {
      appId: this.appId,
      limit: this.itemsPerPage,
      skip: 0,
      search: this.search,
    };
    this.listRichMenu(arge);
  }
  onSwitchChanged(event) {
    if(!event) {
      this.setting = '';
      this.richUserList = [];
    }
  }
  onSwitchOffUpdate(args1) {
      if(this.selecteUser.setting === 'user') {
        args1['deleteUserList'] = this.selecteUser.userList;
      }
      if(this.selecteUser.setting === 'channel') {
        args1['unsetChannel'] = true;
      }
      args1['setting'] = '';
      args1['richUserList'] = [];
      return args1;
  }
}
